import React, { useEffect, useState } from 'react';
import axios from '../../axios-agent';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Container, FormHelperText } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
function UploadCause(props) {
  const [selectedFiles,setSelectedFiles] = useState(null);
  const [message,setMessage]=useState("")
  const [name,setName] = useState("")
  const [disable,setDisable]=useState(true)
  const [errorMessage,setErrorMessage]=useState("")
  const [causeKey,setCauseKey]=useState("")
  const [content,setContent] = useState('')
  const inputImageChangeHandler=(event)=> {
    setErrorMessage("")
    setMessage("")
    const choosedFile=event.target.files[0]
    console.log(choosedFile)
    if(choosedFile.size>2000000){
      setErrorMessage('File size is more than 2 MB.')
      return
    }else if (choosedFile!=undefined){

      setIsSubmitting(true)
      let form_data = new FormData();
      form_data.append('logo',choosedFile );

       axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
      axios.post("/cmpp/cause/"+causeKey+"/logo", form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
        .then(res => {
          console.log(res.data.message)
          setMessage("Upload successful")
          setIsSubmitting(false)
          props.uploadHandler(res.data.message)
        })
        .catch(err => {
          console.log("Hi!")
          setIsSubmitting(false)

            if (err.response) {
              // client received an error response (5xx, 4xx)
              setErrorMessage(err.response.data.message)
            } else if (err.request) {
              console.log(err.request)

              // client never received a response, or request never left
            } else {
              console.log(err)
              // anything else
            }
          }
          )
    }
  }
  useEffect(()=>{
   const tempCauseKey= props.causeKey;
   setCauseKey(tempCauseKey)
   if (tempCauseKey!="" || causeKey!==""){
     setDisable(false)
     setCauseKey(tempCauseKey)
   }else {
     setDisable(true)

   }
  },[props.causeKey,causeKey])
  const handleSubmit = () => {
  if (causeKey!="" && selectedFiles!=null){

  }

  };
  const [isSubmitting,setIsSubmitting]=useState(false);

  useEffect(()=>{
    setContent(<div>
      <Button
        variant="outlined"
        fullWidth
        component="label"
        style={{borderColor:"green", marginTop:"10px"}}
        startIcon={<CloudUploadIcon />}
      >
        Upload Logo
        <input
          required
          type="file"
          accept="image/svg+xml,.jpg,.jpeg,.png"
          disabled={disable}
          onChange={inputImageChangeHandler}
          hidden
        />

      </Button>
      {isSubmitting? <LinearProgress />:null}

      <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
        <b> {message}</b>
      </FormHelperText>
      <FormHelperText error>
        {errorMessage}
      </FormHelperText>
    </div>)
  },[props.causeKey,causeKey,errorMessage,isSubmitting])
  return(
    <div>
      {content}
    </div>
  )




}
export default UploadCause;