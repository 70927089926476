import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Page from "../../../components/Page";
import {Box, Button, Container, FormHelperText, Link, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UploadCause from "../../../components/uploadCause";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Link as RouterLink} from "react-router-dom";
import Style from '../style.module.css';
import {makeStyles} from "@material-ui/core/styles";

 const GrantStart=(props)=>{
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
  }));
  const classes = useStyles();
  const [causeAttribute,setCauseAttribute] = useState({
    value:"",
    key:"",
    url_source:"",
    description:"",
  });

  const [message,setMessage]=useState('')
  const [errorMessage,setErrorMessage]=useState("");
   function validURL(str) {
     var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
       '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
       '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
       '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
     return !!pattern.test(str);
   }
  function changeHandler(event) {
    setErrorMessage("")
    setMessage("")
    let { name, value } = event.target;
     value = value.replace(/[|&;$%@<>+]/g, "");
    // if(name == "key"){
    //   value = value.replace(/[\|&;\$%@"!@#$%^&*()+<>\(\)\+,]/g, "");
    //   value = value.replace(/ /g, '')
    //   value = value.replace(/\\/g, "");
    //   value = value.replace("/", '')
    // }
    setCauseAttribute({
      ...causeAttribute,
      [name]:value
    })
    props.valueChangeHandler(name,value);

  }
  const uploadHandler=(address)=>{
     console.log(address)

    if (address!==""){
      props.valueChangeHandler('logo_url',address);
    }
  };

   function urlChangeHandler(event) {
     let { name, value } = event.target;
     setCauseAttribute({
       ...causeAttribute,
       [name]:value
     });
     setTimeout(
       () =>
       {
         if(!validURL(value)){
            setUrlError(true);
            setUrlHelperText("URL is not valid.")
         }else {
           setUrlError(false);
           setUrlHelperText("")
           props.valueChangeHandler(name,value);

         }


       },
       800
     );
   }

   const [urlHelperText,setUrlHelperText] = useState("");
   const [urlError,setUrlError] = useState(false);
   let content=   <Page
    className={Style.center}
    title="Create Cause"
  >
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm" className={Style.center}>
        <Box mb={3}>
          <Typography
            color="textPrimary"
            variant="h2"
          >
            Welcome
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Users could choose this cause.
          </Typography>
        </Box>
        <TextField
          required
          fullWidth
          label="Organization's name"
          margin="normal"
          name="value"
          onChange={changeHandler}
          value={props.cause["value"]}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Description"
          margin="normal"
          name="description"
          multiline
          rows={2}
          onChange={changeHandler}
          value={causeAttribute["description"]}
          variant="outlined"
        />
          <TextField
            required
            fullWidth
            variant="outlined"
            value={causeAttribute["url_source"]}
            name="url_source"
            error={urlError}
            helperText={urlHelperText}
            className={Style.widthClass}
            onChange={urlChangeHandler}
            label="Web Site Address"

          />


        <UploadCause   causeKey={causeAttribute["key"]}
                       uploadHandler={uploadHandler}
        ></UploadCause>
      </Container>
    </Box>
  </Page>;

  useEffect(()=>{
    setCauseAttribute(props.cause)
  },[props.cause])

  return(
    <div>
      {content}
    </div>

   )
}
export default GrantStart;