import React, { useEffect, useState } from 'react';
import Style from '../avatar/style.css'
import { FormHelperText } from '@material-ui/core';
import SmallWindowBox from "../../../layouts/SmallWindowBox/SmallWindowBox";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "../../../axios-agent";
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";

const Avatar=()=>{
    const [selectedFiles,setSelectedFiles] = useState(null);
    const [message,setMessage]=useState("")
    const [loading,setLoading] = useState(false)
    const [name,setName] = useState("")
    const navigate = useNavigate();
    const [disable,setDisable]=useState(false)
    const [errorMessage,setErrorMessage]=useState("")
    const [isLoggedIn,SetIsLoggedIn] = useState(false)
    const [causeKey,setCauseKey]=useState("")
    const [userid,setUserId] = useState("")
    useEffect(()=>{
        const userId=localStorage.getItem('userid')
        const token=localStorage.getItem('token')
        var auth = {
            userid: userId,
            token: token
        };
        setUserId(userId);
        axios.post('/auth', auth).then(response => {
            setLoading(false)
        }).catch(error => {
             navigate('/login')
        });
    },[])
    const inputImageChangeHandler=(event)=> {
        setErrorMessage("")
        setMessage("")
        const choosedFile=event.target.files[0]
        console.log(choosedFile)
        if (choosedFile!=undefined){
            setIsSubmitting(true)
            let form_data = new FormData();
            form_data.append('logo',choosedFile );
            axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
            axios.post("/cmpp/cause/"+causeKey+"/logo", form_data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(res => {
                    setMessage("Upload successful")
                    setIsSubmitting(false)
                    console.log(res.data);
                })
                .catch(err => {
                    setIsSubmitting(false)
                    setErrorMessage("Upload failed")
                    console.log(err)}
                )
        }
    }

    const handleSubmit = () => {
        if (causeKey!="" && selectedFiles!=null){

        }

    };
    const [isSubmitting,setIsSubmitting]=useState(false);
  return(
      <SmallWindowBox>
          {(loading ?<LinearProgress />: null)}
          <Button
              disabled={loading}
              variant="outlined"
              fullWidth
              component="label"
              style={{borderColor:"green"}}
          >
              Upload Avatar
              <input
                  type="file"
                  accept="image/svg+xml"
                  disabled={disable}
                  onChange={inputImageChangeHandler}
                  hidden
              />

          </Button>
          {isSubmitting? <LinearProgress />:null}

          <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
              <b> {message}</b>
          </FormHelperText>
          <FormHelperText error>
              {errorMessage}
          </FormHelperText>
      </SmallWindowBox>
  );
}
export default Avatar;
