import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from '../../axios-agent';

import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const SITE_KEY = "<YOUR_SITE_KEY>";
const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSubmitting,setIsSubmitting]=useState(false);
  const [FirstName,setFirstName]=useState("");
  const [LastName,setLastName]=useState('');
  const [Email,setEmail]=useState("");
  const [ErrorHappened,setErrorHappened]=useState(false);
  const [Password,setPassword]=useState("");;
  const [ErrorsMessage,setErrorsMessage]=useState("");
  const [message,setMessage]=useState('')
  const [successProcess,setSuccessProcess]=useState(false)
  const FirstNameChange=(event)=>{
    setErrorHappened(false)
    setErrorsMessage("")
    setFirstName(event.target.value)

  }
  const LastNameChange=(event)=>{
    setErrorHappened(false)
    setErrorsMessage("")
    setLastName(event.target.value)
  }
  const EmailChange=(event)=>{
    setErrorHappened(false)
    setErrorsMessage("")
    setEmail(event.target.value)
  }
  const PasswordChange=(event)=>{
    setErrorHappened(false)
    setErrorsMessage("")
    setPassword(event.target.value)

  }
  const validateEmail=(email)=> {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const validatePassword=(password)=>{
    if (password.length<8){
      return false
    }
    return true
  }
  const SubmitHandler=()=>{
    setMessage("")
    setErrorsMessage("")
    console.log("submit")
    if (!validateEmail(Email)){
      setErrorsMessage('Invalid email address.')
      setErrorHappened(true)
      return
    }
    if (!validatePassword(Password)){
      setErrorsMessage('Password minimum length is 8 characters')
      setErrorHappened(true)
      return
    }
    setIsSubmitting(true);
    const user={
      firstname:FirstName,
      lastname:LastName,
      email:Email,
      password:Password
    }

    axios.post('/register', user).then(response => {
      setMessage("User created. Call admin.")
      setIsSubmitting(false)
      console.log(response)
    }).catch(responseError=>{
      if( responseError.response ){
        // console.log(responseError.response.data); // => the response payload
        setIsSubmitting(false)
        setErrorsMessage(responseError.response.data.message)
      }else {
        setIsSubmitting(false)
        setErrorsMessage("You could not register")
      }
     })

  }



  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">


                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onChange={FirstNameChange}
                  value={FirstName}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onChange={LastNameChange}
                  value={LastName}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onChange={EmailChange}
                  type="email"
                  value={Email}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  onChange={PasswordChange}
                  type="password"
                  value={Password}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >

                </Box>
          <FormHelperText error>
          <h3>   <b> {ErrorsMessage}</b></h3>
          </FormHelperText>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={SubmitHandler}
                  >
                    Sign up now
                  </Button>
                </Box>
          <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
            <h3>   <b> {message}</b></h3>
          </FormHelperText>
          {isSubmitting? <LinearProgress />:null}

          <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>

        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
