import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../axios-agent';
import Card from '@material-ui/core/Card';
import moment from 'moment';

import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Label } from 'semantic-ui-react';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Details from './Details';
import causeLogo from './Logo';
import Container from '@material-ui/core/Container';
import Page from '../../../components/Page';
import Profile from './Logo';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));
function  CauseDetail(props) {
  const classes = useStyles();

  const navigate =useNavigate()
    const [cause,setCause]=useState()
  const [content,setContent]=useState(<div><LinearProgress />
    <LinearProgress color="secondary" /></div>)
  const { id } = useParams()
  useEffect(()=>{
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    axios.get('/cause/ein/'+id).then(response => {
      setCause(response.data)
    }).catch(response=>{
    })
  },[])
  useEffect(()=>{
   if (cause!==undefined){
     console.log(cause)
     setContent(    <Page
       className={classes.root}
       title="Cause detail"
     >
       <Container maxWidth="lg">
         <Grid
           container
           spacing={3}
         >
           <Grid
             item
             lg={4}
             md={6}
             xs={12}
           >
             <Profile value={cause.organization_name} keyName={cause.key} causeLogo={cause.logo_url} ein={cause.ein} />
           </Grid>
           <Grid
             item
             lg={8}
             md={6}
             xs={12}
           >
             <Details value={cause.organization_name}
                      keyName={cause.key}
                      type={cause.type}
                      image={cause.logo_url}
                      description={cause.description}
                      url_source={cause.url}
                      id={id}
                      dba={cause.dba}
             />
           </Grid>
         </Grid>
       </Container>
     </Page>)
   }
  },[cause])
  return(
    <div>
      {content}
    </div>

  )
}
export default CauseDetail;