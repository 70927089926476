import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types';
import Style from '../Detail/style.module.css';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormHelperText
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import green from '@material-ui/core/colors/green';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from '../../../axios-agent';
import CauseDropdown from '../../../utils/Cause/dropdown/CauseDropdown';
import { Label } from 'semantic-ui-react';


const roles = [
  {
    value: 'member',
    label: 'Member'
  },
  {
    value: 'cmpp',
    label: 'Cmpp'
  },
  {
    value: 'admin',
    label: 'Admin'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = (props) => {
  const [loadingCause,setLoadingCause]=useState(true)
  const [message,setMessage]=useState('')
  const [isSubmitting,setIsSubmitting]=useState(false)
  const [errorMessage,setErrorMessage]=useState("");
  const [causeList,setCauselist] = useState([])

  const navigate=useNavigate();
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    role: '',
    cmpp_registered: false,
    password: '',
    cause:'',
    author_point:0,
    referral_point:0,
    reader_point:0
  });
  let tempCauseList = [{
    "value": "General fund",
    "text": "General fund",
    "key": "alz",
    "logo_url": "",
    "image": { avatar: true, src: "https://u.givingforward.org/logo.png" },
    "description":"",
    "hero_image":""
  },
  ]
  useEffect(()=>{
    console.log(props)
    console.log((props.role))
    setValues({
      first_name: props.first_name,
      last_name: props.last_name,
      role: props.role,
      cmpp_registered: props.cmpp_registered,
      cause: props.cause,
      author_point:props.author_point,
      referral_point:props.referral_point,
      reader_point:props.reader_point,
      password: ''
    });

    axios.get('/cause').then(response => {
      tempCauseList = response.data;
      tempCauseList.map(cauesTemp=>{
        let tmpCause= {
          key: cauesTemp.key,
          text: cauesTemp.value,
          value: cauesTemp.value,
          logo_url:cauesTemp.logo_url,
          image: {  avatar: true, src: cauesTemp.image }

        };
        causeList.push(tmpCause)
        setCauselist(causeList)

      })
      setLoadingCause(false)
    }).catch(error => {
      setLoadingCause(false)

    })
  },[])
  function handleChangeCheckBox(event) {

    setValues({
      ...values,
      ["cmpp_registered"]: event.target.checked
    });
  }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function handleCmppRegistredChange(event) {
    if (event.target.value=="true"){
        setValues({ ...values, ["cmpp_registered"]: true })
      return
    }
    setValues({ ...values, ["cmpp_registered"]: false })
  }


  function saveChangesHandler() {
    setIsSubmitting(true)
    setMessage("")
    setErrorMessage("")
    const id=props.id
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    axios.put('/admin/user/'+id,
      values).then(response=> {
      setIsSubmitting(false)
      setMessage("user updated")
      console.log(response)
    }).catch(responseError=>{

      if( responseError.response ){
        // console.log(responseError.response.data); // => the response payload
        setIsSubmitting(false)
        setErrorMessage(responseError.response.data.message)
      }else {
        setIsSubmitting(false)
        setErrorMessage("Could not update user")
      }
    });
  }

  const causeChangeHandler=(key)=>{
    console.log(key)
    setValues({
      ...values,
      ["cause"]:key
    });
   // props.causeChanged(key)
  };
  return (
    <form
      autoComplete="off"
      noValidate
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="User"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="First name"
                name="first_name"
                onChange={handleChange}
                value={values.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                name="last_name"
                onChange={handleChange}
                value={values.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                disabled={true}
                fullWidth
                label="Author's Point"
                name="author_point"
                onChange={handleChange}
                value={values.author_point}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                disabled={true}
                fullWidth
                label="Reader's Point"
                name="reader_point"
                onChange={handleChange}
                value={values.reader_point}
                variant="outlined"
              />
            </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  className={Style.padding}
                  fullWidth
                  label="Role"
                  name="role"
                  onChange={handleChange}

                  select
                  SelectProps={{ native: true }}
                  value={values.role}
                  variant="outlined"
                >
                  {roles.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                itemDetail
                md={6}
                xs={12}
              >
                <FormControlLabel
                  className={Style.verticalCenter}
                  control={
                    <Checkbox
                      checked={values.cmpp_registered}
                      onChange={handleChangeCheckBox}
                      name="cmpp_registered"
                      color="primary"
                    />
                  }
                  label="Make user activated"
                />
              </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                id="password"
                label="Password"
                type="password"
                name="password"
                fullWidth
                onChange={handleChange}
                autoComplete="current-password"
                variant="outlined"
              />
            </Grid>
            <Grid
              itemDetail
              md={6}
              xs={12}
            >
              <CauseDropdown
                causeChanged={causeChangeHandler}
                causeList={causeList}
                defaultCause={values.cause}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
          <b> {message}</b>
        </FormHelperText>
        <FormHelperText error>
          {errorMessage}
        </FormHelperText>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
            <Button color="green" onClick={()=>{ navigate(-1)}}>Get Back</Button>
            <Button disabled={isSubmitting} onClick={saveChangesHandler}>Save Changes</Button>
          </ButtonGroup>

        </Box>
        {isSubmitting? <LinearProgress />:null}

      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
