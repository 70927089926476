import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from '../../../axios-agent';
import {
  Box,
  Button,
  Card, CardActions,
  CardContent,
  CardHeader, CircularProgress,
  Divider,
  FormHelperText,
  Grid, IconButton, Snackbar,
  TextField
} from '@material-ui/core';
import Style from '../../User/Detail/style.module.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CauseDropdown from '../../../utils/Cause/dropdown/CauseDropdown';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Page from '../../../components/Page';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';
import { AuthContext } from '../../../context/auth-context';
import { Alert } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "50%",
    marginLeft:'auto',
    marginRight:"auto",

  },

  media: {
    height: 400,
    width: 400,
    objectFit: 'cover',
    marginLeft:'auto',
    marginRight:'auto'
  },
  btnRequest: {
    objectFit: 'cover',
    marginLeft:'auto',
    marginRight:'auto'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const JobDetail = (props) => {
  const { state } = useLocation();
  const authContext = useContext(AuthContext);
  const [authorName,setAuthorName] = useState("User")
  const [loadingRequest,setLoadingRequest] = useState(false)
  const [authorId,setAuthorID] =useState("")
  const [jobId,setJobId]=useState("")
  const [authorAvatar,setAuthorAvatar] = useState("")
  var tempJob = {
    job_id : "",
    title:"",
    caption:"",
    salary:0,
    owner_id:"",
    final_candidate_id:"",
    cause:"",
    status:0,
    image:"",

  }
  const [openAlert,setOpenAlert] = useState(false);
  const [errorHappened,setErrorHappened] = useState(false)
  const [message,setMessage] = useState("")
  const [job,setJob] = useState(tempJob);
  const [loading,setLoading] = useState(true);
  const [requestIdOfUsers,setRequestsIdS] = useState([]);
  const [isRequested,setIsRequest] =useState(false)
  const [content,setContent] = useState(<CircularProgress></CircularProgress>);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoggedIn,setLoggedIn] = useState(false);


  useEffect(()=>{
    const readerId= localStorage.getItem('userid')
    requestIdOfUsers.map((id)=>{
      if (id === readerId){
        setIsRequest(true);
      }
    })
  },[requestIdOfUsers])
  useEffect(()=>{
    if(job.job_id!==undefined && job.job_id!==null){
      axios.get('/public/user/'+job.owner_id).then(response => {
        setLoading(false)
        setAuthorID(response.data.hash)
        const firstname = response.data.firstname
        const  lastname = response.data.lastname
        const avatar = response.data.avatar
        const username=response.data.username
        if (firstname !==undefined && firstname!==""){
          setAuthorName(firstname)
        }else if (lastname!== undefined && lastname!==""){
          setAuthorName(lastname)
        }else if(username!==undefined && username!=="") {
          setAuthorName(username)
        }
        if (avatar!==undefined && avatar!==""){
          setAuthorAvatar(avatar)
        }

      }).catch(error => {
        setLoading(false)
        setAuthorName("user")
      })
    }
  },[job])
  useEffect(()=>{
    setLoggedIn(authContext.isAuth);
  });
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const sendRequest = () => {
    if(isRequested){
      return;
    }
    setLoadingRequest(true)
    const readerId= localStorage.getItem('userid')
    if (job.job_id ===undefined || job.job_id==="" || readerId===undefined || readerId===""){
      return
    }
    var requestMessage ={
      "sender_user_id":readerId,
      "job_id": job.job_id
    }
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    axios.post("/user/"+readerId+"/job/request", requestMessage)
      .then(res => {
        setIsRequest(true)
        setLoadingRequest(false)
        setErrorHappened(false)
        setMessage("Request Sent successfully!")
        setOpenAlert(true)

      })
      .catch(err => {
        setLoadingRequest(false)
        setErrorHappened(true)
        setMessage("Could not send request!")
        setOpenAlert(true)

        }
      )
  }
  useEffect(()=>{
    tempJob= state
    if(tempJob===null){
      axios.get('/job/'+id).then(response => {
        tempJob=response.data
        let requestsIds =[]
        if (response.data.requests !==null && response.data.requests !==undefined){
          response.data.requests.map((request)=>{
            requestsIds.push(request["sender_user_id"])
          });
          console.log(requestsIds)
          setRequestsIdS(requestsIds)
        }

        setJob(tempJob)
      }).catch(error => {
        setContent(         <Card>
          <Typography variant="body1" color="textPrimary" component="p" style={{margin:5, width:"50%", marginLeft:"auto",marginRight:"auto"}}>
             <span style={{textAlign:"center"}}>

             </span>
            <br></br>
            <Button variant="contained" color="secondary" href={'/job/list'}>
              Job Not found, Back to list
            </Button>
          </Typography>
        </Card>)
        return
      })
    }else {
      let requestsIds =[]
      if (tempJob.requests!==null && tempJob.requests!==undefined){
        tempJob.requests.map((request)=>{
          requestsIds.push(request["sender_user_id"])
        });
        setRequestsIdS(requestsIds)
      }

      setJob(tempJob)
    }
  },[state,id])
  useEffect(()=>{
    let isLoggedIn = authContext.isAuth

    //....................................................................
    const readerId= localStorage.getItem('userid')
    let RequestJob = <div></div>
    if (isLoggedIn && !isRequested){
      RequestJob = <div>
        <Divider />
          <span color="textSecondary" style={{margin:20}}>
            This Job has {requestIdOfUsers.length} requests!
          </span>
        <Box
          display="flex"
          justifyContent="flex-end"
        >

          <Button variant="outlined" color="primary"  onClick={sendRequest} className={classes.btnRequest}>
            Send Request
          </Button>
        </Box>

      </div>
    }else if (!isLoggedIn && !isRequested){
      RequestJob=   <Page
        className={Style.center}
        title="Login Required"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm" className={Style.center}>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                You are not logged in!
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Please Register if you didn't yet!
              </Typography>
            </Box>
            <Button variant={"outlined"} href={"/login"}> Login</Button>


          </Container>
        </Box>
      </Page>;
    }else if(isRequested){
      RequestJob=<Button variant="outlined"  style={{margin:20}}>Your request has been submitted for this position.</Button>
    }
    if (authorId === readerId){
      RequestJob =""
    }


    //...................................................................
   if (job==undefined ||job==null || job.job_id=="" ){
     setContent(<div>
       <Page
         title={"Job Detail"}
       >
         <LinearProgress></LinearProgress>

       </Page>
     </div>)
   }else {
     tempJob=job;
     setContent(
       <Page
         title={tempJob.title}
       >
         {loadingRequest? <LinearProgress></LinearProgress>:""}

         <Card className={classes.root}>
           <CardHeader
             avatar={
               loading? <CircularProgress></CircularProgress>:   <Avatar aria-label="recipe" src={authorAvatar} className={classes.avatar}>
                 {authorName[0].toUpperCase()}
               </Avatar>
             }
             // action={
             //   <IconButton aria-label="settings">
             //     <MoreVertIcon />
             //   </IconButton>
             // }
             title= {loading ? <LinearProgress></LinearProgress>:authorName}
             subheader={tempJob.created}
           />
           <CardMedia
             className={classes.media}
             image={tempJob.image}
             title={tempJob.title}
           />

           <CardContent>
             <span color="textPrimary" style={{margin:5}}>
               <h2>{tempJob.title}</h2>
          </span>
             <br></br>
            <span color="textSecondary" style={{margin:5}}>
            Salary: {tempJob.salary} {" "}$
          </span>
             <br></br>
             <Typography variant="body1" color="textPrimary" component="p" style={{margin:5}}>
               {tempJob.caption}
             </Typography>

           </CardContent>
           {RequestJob}
           <CardActions disableSpacing>
             <Button aria-label="add to favorites"
             onClick={()=>{
               navigate('/job/list')
             }}
             >
               Back to job list
             </Button>

             {/*<Button*/}
             {/*  className={clsx(classes.expand, {*/}
             {/*    [classes.expandOpen]: expanded,*/}
             {/*  })}*/}
             {/*  onClick={handleExpandClick}*/}
             {/*  aria-expanded={expanded}*/}
             {/*  aria-label="show more"*/}
             {/*>*/}
             {/*  Request for job*/}
             {/*  <ExpandMoreIcon />*/}
             {/*</Button>*/}
           </CardActions>
           <Collapse in={expanded} timeout="auto" unmountOnExit>
             <CardContent>
               {RequestJob}
             </CardContent>
           </Collapse>

         </Card>
         <Snackbar open={openAlert} autoHideDuration={6000} onClose={()=>{setOpenAlert(false)}}>
           <Alert onClose={()=>{setOpenAlert(false)}} severity={errorHappened? "error":"success"}>
             {message}
           </Alert>
         </Snackbar>
       </Page>)
   }


  },[props,isLoggedIn,state,expanded,authorAvatar,authorName,job,authorId,loading,message,openAlert,errorHappened,loadingRequest])
  return (
    <div>
      {content}
    </div>
  );
};

JobDetail.propTypes = {
  className: PropTypes.string
};

export default JobDetail;
