import Classes from "./SmallWindowBox.module.css";
import React from "react";

const SmallWindowBox = (props) => {
    return (
        <div className={Classes.rcorners3}>
            {props.children}
        </div>
    );
};
export default SmallWindowBox;