import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Style from './style.module.css';
import GrantStart from './GrantStart/GrantStart';
import GrantBasic from './GrantBasic/GrantBasic';
import GrantReview from './GrantReview/review';
import { FormHelperText } from '@material-ui/core';
import axios from '../../axios-agent';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardMassage from '../Content/Massage/CardMassage/CardMassage';
const QontoConnector = withStyles({

  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Start', 'Basic Information', 'Review'];
}



const Grant=(props) =>{

  const [newCause,setCause] = useState({
    value:"",
    key:"",
    logo_url:"",
    description:"",
    tin:"",
    email:"",
    location:"",
    category:"",
    url_source:""
  });
  function validateSSNorTIN(data) {
    var ssn = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
    var tin = /^\d{2}-?\d{7}$|^XX-XXXXXXX$/;

    if (ssn.test(data)||tin.test(data)) {
      return true;
    } else {
      return false;

    }
  }
  const registerHandler=()=>{
    setIsSubmitting(true)
    axios.post("/cause/grant", newCause)
      .then(res => {
        setIsSubmitting(false)
        setSuccessRegister(true)
        console.log(res.data.message)

      })
      .catch(err => {
        setIsSubmitting(false)
        setErrorHappened(true)
        setErrorMessage("cause could not create")
        if (err.response) {
            // client received an error response (5xx, 4xx)
            setErrorMessage(err.response.data.message)
          } else if (err.request) {
            console.log(err.request)

            // client never received a response, or request never left
          } else {
            console.log(err)
            // anything else
          }
        }
      )
  }
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  function extractHostname(url) {
    var hostname, domain;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];
    domain = hostname.replace("www.", "")
    domain = domain.replace(".com", "")
    domain = domain.replace(".net", "")
    domain = domain.replace(".org", "")
    domain = domain.replaceAll(".", "-")
    return domain;
  }
  const stepValueChangeHandler=(name,value)=>{
    console.log(name,value)
    if(name==='email'){
      if (!validateEmail(value)){
        return
      }
    }
    if (name==='tin'){
      if (!validateSSNorTIN(value)){
        return;
      }
    }
    if(name==='url_source'){
     let tempKey= extractHostname(value);
     console.log(tempKey)
     if(tempKey!==""){
       setCause({
         ...newCause,
         ["key"]:tempKey,
         ["url_source"]:value
       })
     }
    return;
    }
    setCause({
      ...newCause,
      [name]:value
    })

  }
  const ValidateStepOne=()=>{
    if (newCause['email'] ===""){
      setErrorMessage('Email field is required.')
      setErrorHappened(true)
      return false
    }else {
      setErrorMessage('')
      setErrorHappened(false)
      return true
    }
  }
  const ValidateStepZero=()=>{
    if (newCause['value'] ===""){
      setErrorMessage('Organization\'s name is required.')
      setErrorHappened(true)
      return false
    }else if (newCause['url_source'] ===''){
      setErrorMessage('Web site address is required.')
      setErrorHappened(true)
      return false
    } else if (newCause['logo_url']===""){
      setErrorMessage('Please upload cause logo.')
      setErrorHappened(true)
      return false
    }
    else {
      setErrorMessage('')
      setErrorHappened(false)
      return true
    }
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <GrantStart cause={newCause} valueChangeHandler={stepValueChangeHandler}></GrantStart>;
      case 1:
        return <GrantBasic cause={newCause} valueChangeHandler={stepValueChangeHandler}></GrantBasic>;
      case 2:
        return <GrantReview cause={newCause} registerHandler={registerHandler}></GrantReview>;
      default:
        return 'Unknown step';
    }
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === steps.length){
      return
    }
    switch (activeStep) {
      case 0:
        if (ValidateStepZero()){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        if(ValidateStepOne()){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [errorMessage,setErrorMessage] = useState('');
  const [errorHappened,setErrorHappened]=useState(false)

  const [isSubmitting,setIsSubmitting] =useState(false);
  const [successRegister,setSuccessRegister] = useState(false)
  const [Message,setMessage] = useState('');
  return (
    <div className={classes.root}>

      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={Style.center}>
        {successRegister ? (
          <div>
             <CardMassage headr={"Congratulation"} image={"https://goodbuzz.org/wp-content/uploads/2020/07/544x180_logo_heart-03.jpg" }
            content={"Cause created, Now admin should active your cause and account. We will contact you."}
            >

            </CardMassage>
          </div>

          // <div>
          //   <Typography className={classes.instructions}>
          //     All steps completed - you&apos;re finished
          //   </Typography>
          //   <Button onClick={handleReset} className={classes.button}>
          //     Reset
          //   </Button>
          // </div>
        ) : (
          <div>
            <br></br>
            <Typography  className={classes.instructions}>{getStepContent(activeStep,newCause)}</Typography>
            <div className={Style.center}>
              <FormHelperText error={errorHappened}> {errorMessage}</FormHelperText>
            </div>
            <br></br>
            <div >
              {isSubmitting? <LinearProgress />:null}
            </div>
            <br></br>
            <div  className={classes.root}>

            <div className={Style.center}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={activeStep === steps.length - 1 ? registerHandler : handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Grant;