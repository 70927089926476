import React, { useEffect, useState } from 'react';
import {
    Box, CircularProgress,
    Container,
    Grid,
    makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import JobCard from './jobCard';
// import data from './data';
import axios from '../../../axios-agent';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    productCard: {
        height: '100%'
    }
}));

const MyJobList = ({ className, type, ...rest }) => {
    var job = {
        job_id : "",
        title:"",
        caption:"",
        salary:0,
        owner_id:"",
        final_candidate_id:"",
        cause:"",
        status:0,
        image:"",
        created:""
    }
    const classes = useStyles();
    const [loading,setLoading] = useState(true);
    // const [products] = useState(data);
    const [dataFound,setDataFound] = useState([]);
    const [title,setTitle] = useState("");
    const [jobCFetched,setJobs] = useState([]);
    const [searchInput,setSearchInput] = useState("");
    const inputSearchHandler=(value)=>{
        setSearchInput(value);
    };
    const errorHappened=()=>{}
    useEffect(()=>{
        if (searchInput ===""){
            console.log(jobCFetched.length)
            console.log(dataFound.length)
            setDataFound(jobCFetched);
        }else {
            let newListFound = [];
            jobCFetched.map((job)=>{
                if (job.caption.includes(searchInput)){
                    newListFound.push(job);
                }
            })
            setDataFound(newListFound);
        }
    },[searchInput])
    useEffect(()=>{
        var jobs=[];
        const hash = localStorage.getItem('userid')
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')

        switch (type) {
            case "requested":
                setTitle("Request List");
                axios.get('/user/'+hash+'/job/request').then(response => {
                    setLoading(false)
                    let jobList = response.data;
                    if(jobList.length>0){
                        setJobs(jobList);
                        setDataFound(jobList);


                    }

                }).catch(error => {
                    setLoading(false)
                    errorHappened();
                })
                break;
            case 'posted':
                setTitle("Job Posted");
                axios.get('/user/'+hash+'/job').then(response => {
                    setLoading(false)
                    let jobList = response.data;
                    if(jobList.length>0){
                        setJobs(jobList);
                        setDataFound(jobList);


                    }

                }).catch(error => {
                    setLoading(false)
                    errorHappened();
                })
                break;
            case 'done':
                setTitle("Job Done");
                axios.get('/user/'+hash+'/job/done').then(response => {
                    setLoading(false)
                    let jobList = response.data;
                    if(jobList.length>0){
                        setJobs(jobList);
                        setDataFound(jobList);


                    }

                }).catch(error => {
                    setLoading(false)
                    errorHappened();
                })
                break;
            case 'accepted':
                setTitle("Job accepted!");
                axios.get('/user/'+hash+'/job/start').then(response => {
                    setLoading(false)
                    let jobList = response.data;
                    if(jobList.length>0){
                        setJobs(jobList);
                        setDataFound(jobList);


                    }

                }).catch(error => {
                    setLoading(false)
                    errorHappened();
                })
                break;
            case 'approved':
                setTitle("Job Approved!");
                axios.get('/user/'+hash+'/job/approved').then(response => {
                    setLoading(false)
                    let jobList = response.data;
                    if(jobList.length>0){
                        setJobs(jobList);
                        setDataFound(jobList);


                    }

                }).catch(error => {
                    setLoading(false)
                    errorHappened();
                })
                break;
            default:
                axios.get('/job').then(response => {
                    setLoading(false)
                    let jobList = response.data;
                    if(jobList.length>0){
                        setJobs(jobList);
                        setDataFound(jobList);


                    }

                }).catch(error => {
                    setLoading(false)
                })
                break;

        }


    },[])
    return (
        <Page
            className={classes.root}
            title={title}
        >
            <Container maxWidth={false}>
                <Toolbar  inputSearchHandler={inputSearchHandler} title={title}/>
                {loading? <LinearProgress  />:null}
                <Box mt={3}>
                    <Grid
                        container
                        spacing={3}
                    >

                        {dataFound.map((job) => (
                            <Grid
                                item
                                key={job.id}
                                lg={4}
                                md={6}
                                xs={12}
                            >
                                <JobCard
                                    className={classes.productCard}
                                    job={job}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box
                    mt={3}
                    display="flex"
                    justifyContent="center"
                >
                    {/*<Pagination*/}
                    {/*  color="primary"*/}
                    {/*  count={3}*/}
                    {/*  size="small"*/}
                    {/*/>*/}
                </Box>
            </Container>
        </Page>
    );
};

export default MyJobList;
