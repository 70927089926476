import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Container, FormHelperText } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from '../../../axios-agent';
function UploadJobImage(props) {
  const [selectedFiles,setSelectedFiles] = useState(null);
  const [message,setMessage]=useState("")
  const [name,setName] = useState("")
  const [disable,setDisable]=useState(true)
  const [errorMessage,setErrorMessage]=useState("")
  const [content,setContent] = useState('')
  const inputImageChangeHandler=(event)=> {
    setErrorMessage("")
    setMessage("")
    const choosedFile=event.target.files[0]
    console.log(choosedFile)
    if(choosedFile.size>2000000){
      setErrorMessage('File size is more than 2 MB.')
      return
    }else if (choosedFile!=undefined){

      setIsSubmitting(true)
      let form_data = new FormData();
      form_data.append('image',choosedFile );

    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
      axios.post("/user/job/image", form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
        .then(res => {
          console.log(res.data.message)
          setMessage("Upload successful")
          setIsSubmitting(false)
          props.uploadHandler(res.data.message)
        })
        .catch(err => {
            setIsSubmitting(false)

            if (err.response) {
              // client received an error response (5xx, 4xx)
              setErrorMessage(err.response.data.message)
            } else if (err.request) {
              console.log(err.request)

              // client never received a response, or request never left
            } else {
              console.log(err)
              // anything else
            }
          }
        )
    }
  }


  const [isSubmitting,setIsSubmitting]=useState(false);

  useEffect(()=>{
    setContent(<div>
      <Button
        variant="outlined"
        fullWidth
        component="label"
        style={{borderColor:"green", marginTop:"10px"}}
        startIcon={<CloudUploadIcon />}
      >
        Upload Image
        <input
          required
          type="file"
          accept="image/svg+xml,.jpg,.jpeg,.png"
          onChange={inputImageChangeHandler}
          hidden
        />

      </Button>
      {isSubmitting? <LinearProgress />:null}

      <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
        <b> {message}</b>
      </FormHelperText>
      <FormHelperText error>
        {errorMessage}
      </FormHelperText>
    </div>)
  },[errorMessage,isSubmitting,message])
  return(
    <div>
      {content}
    </div>
  )




}
export default UploadJobImage;