import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import StyleClasses from './Convertor.module.css'

import {Box, Button, Checkbox, Container, FormHelperText, LinearProgress, Link, Typography} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from "../../../axios-agent";
import Page from "../../../components/Page";
import {Image} from "semantic-ui-react";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
}));
const Converter = () => {
    const classes = useStyles();
    const [message,setMessage]=useState('')
    const [qrUrl,setQrUrl]=useState("");
    const [newUrl,setNewURL]=useState("");
    const [input_link,setInputLink] = useState("")
    function valueChangeHandler(event) {
        console.log(event.target.value)
        setInputLink(event.target.value)
    }

    const [isSubmitting,setIsSubmitting]=useState(false);

    function generateLink() {
        setErrorMessage("")
        const isValid=validURL(input_link);
        if(!isValid){
            setErrorMessage("URL is Not Valid")
            return
        }
        const newUrl={
            url:input_link,
        }
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
        setIsSubmitting(true)
        axios.post('/qr',
            newUrl).then(response=>{
            setIsSubmitting(false)

            console.log(response)
            setQrUrl(response.data.QR_Url);
            setNewURL(response.data.newUrl);
        }).catch(response=>{

            setIsSubmitting(false)
            setErrorMessage(response.data)
            console.log(response)
        })

    }

    const [errorMessage,setErrorMessage]=useState("");
    const [content,setContent]=useState(<div>loading</div>);

    function copyHandler() {

    }

    useEffect(()=>{
        setContent(    <Page
            title="Generate Link"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container  className={StyleClasses.center}>
                    <Box>
                        <Typography
                            color="textPrimary"
                            variant="h2"
                        >
                            New Link
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            You can use QR Code as well
                        </Typography>
                    </Box>
                    <TextField
                        fullWidth
                        label="Input Link"
                        margin="normal"
                        name="link"
                        onChange={valueChangeHandler}
                        value={input_link}
                        variant="outlined"
                    />

                    <FormHelperText hidden={input_link!=""}>
                        Please enter url
                    </FormHelperText>
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={-1}
                    >
                    </Box>
                    <FormHelperText error>
                        {errorMessage}
                    </FormHelperText>
                    <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
                        <b> {message}</b>
                    </FormHelperText>
                    <Box my={2}>
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={generateLink}
                        >
                            Generate Link
                        </Button>
                    </Box>

                    {newUrl!==""?<div className={StyleClasses.center}>
                        <Image src={qrUrl} size='medium' bordered />
                        <div style={{border:"double",padding:"10px"}}>{newUrl}</div>
                    </div> :null}

                    {isSubmitting? <LinearProgress />:null}
                </Container>
            </Box>
        </Page>)
    },[qrUrl,newUrl,isSubmitting,message,errorMessage,input_link])
    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }
    return(
  <div>
      {content}
  </div>
  )
}
export default Converter;