import React, {useEffect, useState} from 'react'
import { Search, Grid, Header, Segment } from 'semantic-ui-react'
import {encode as base64_encode} from "base-64";
import axios from "../../../axios-agent";
import {useNavigate} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Classes from "../search-cause/search.module.css"

const useStylesButton =  makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));


function SearchCause() {
    const [causes,setCauses] = useState([])
    const [results,setResults] = useState([])
    const [loading,setLoading] = useState(false)
    const btnClasses = useStylesButton();

    const resRender = (obj) =>{
        console.log(obj.ein)
        return (  <span key={obj.text}>
            {obj.title} - {obj.ein}
            <br></br>
                      <Button
                          variant="outlined"
                          style={{borderColor:"green"}}
                          className={btnClasses.button}
                          size="small"
                          onClick={()=>{showCauseDetail(obj.ein)}}
                      >Edit</Button>
      </span>)
    };
    const navigate = useNavigate();
    function showCauseDetail(id){
        navigate('/app/cause/'+id)

    }
    const [searchTerm, setSearchTerm] = useState(<div></div>)
    useEffect(()=>{
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            // axios.defaults.headers.common['x-api-key'] = "6oHDhYt5mW0rcL2ynt4Thw1mUhaLtZOXynvNW8DVfVtc57gMgZ2g55Y2mYfW"
            if(searchTerm!==""){
                setLoading(true)
                let encoded = base64_encode(searchTerm);
                axios.get('/cause/search?input='+encoded).then(response => {
                    setLoading(false)
                    setCauses(response.data)
                }).catch(err=>{
                    setLoading(false)
                })
            }

        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    },[searchTerm])
    useEffect(()=>{
        let tempList=[]

        causes.map((cause=>{
            console.log(cause)
            let temp=  {
                title: cause.text,
                description: "",
                image: cause.logo_url,
                ein: cause.ein
                }
                tempList.push(temp)
        }))
        setResults(tempList)
    },[causes])
    return (
        <Search
            className={Classes.centerDiv}
            onResultSelect={(e, data) =>
               console.log(e,data)
            }
            onSearchChange={ (e) => setSearchTerm(e.target.value)}
            icon="search"
            placeholder="Search..."
            results={results}
            onSelectionChange={(e,data)=>{console.log(data)}}
            resultRenderer={resRender}
        />
    );
}

export default SearchCause
