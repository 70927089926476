import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../axios-agent';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ProfileDetails from './Detail';
import Profile from './Profile';
import Container from '@material-ui/core/Container';
import Page from '../../../components/Page';
import CssClasses from '../Detail/style.module.css';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

function UserDetail(props) {
  const classes = useStyles();

  const navigate =useNavigate()
    const [user,setUser]=useState()
  const [content,setContent]=useState(<div><LinearProgress />
    <LinearProgress color="secondary" /></div>)
  const { hash } = useParams()
  useEffect(()=>{
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    axios.get('/admin/user/'+hash).then(response => {
      setUser(response.data)
    }).catch(response=>{

    })



  },[])
  useEffect(()=>{
   if (user!==undefined){
     setContent(    <Page
       className={classes.root}
       title="User Detail"
     >
       <Container maxWidth="lg">
         <Grid
           container
           spacing={3}
         >
           <Grid
             item
             lg={4}
             md={6}
             xs={12}
           >
             <Profile name={user.first_name+" "+user.last_name}
                      role={user.role}
                      email={user.email}
                      cause={user.cause}
             />
           </Grid>
           <Grid
             item
             lg={8}
             md={6}
             xs={12}
           >
             <ProfileDetails first_name={user.first_name}
                             role={user.role}
                             last_name={user.last_name}
                             cmpp_registered={user.cmpp_registered}
                             cause={user.cause}
                             reader_point={user.reader_point}
                             referral_point={user.referral_point}
                             author_point={user.author_point}
                             id={hash}
             />
           </Grid>
         </Grid>
       </Container>
     </Page>)
   }
    console.log(user)
  },[user])
  return(
    <div>
      {content}
    </div>

  )
}
export default UserDetail;