import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Page from '../../../components/Page';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container, FormControl,
  FormHelperText, InputLabel,
  Link,
  Typography
} from '@material-ui/core';
import Style from './jobNew.module.css'
import { Link as RouterLink } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from '../../../axios-agent';
import LinearProgress from '@material-ui/core/LinearProgress';
import UploadCause from '../../../components/uploadCause';
import { AuthContext } from '../../../context/auth-context';
import UploadJobImage from './uploadImage';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
}));
const JobNew=(props)=>{
  let newJob= {
    title:"",
    caption:"",
    salary:0,
    image:"",
    category:""
  };
  const [title,setTitle] = useState("")
  const [caption,setCaption] = useState("")
  const [image,setImage] = useState("")
  const [salary,setSalary] = useState(0)
  const [category,setCategory] = useState('General')
  const classes = useStyles();
  const [errorMessage,setErrorMessage]=useState("");
  const [message,setMessage]=useState("");
  const [content,setContent] = useState(<CircularProgress></CircularProgress>)

  const [isSubmitting,setIsSubmitting] = useState(false);

  const [isLoggedIn,setLoggedIn] = useState(false)
  const authContext = useContext(AuthContext);
  const uploadHandler=(url)=>{
        setImage(url)
  }
  const submitJob=()=>{
    setErrorMessage("")

    if (title===""){
      setErrorMessage("Please enter title")
      return
    }
    if (caption ===""){
      setErrorMessage("Please enter caption")
      return
    }
    if (salary<=0){
      setErrorMessage("Salary input is not valid")
      return
    }
    if (image===""){
      setErrorMessage("Please upload image")
      return
    }


    newJob.title=title
    newJob.salary=salary
    newJob.caption=caption
    newJob.image = image
    newJob.category =category
    const userID= localStorage.getItem('userid')
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    setIsSubmitting(true)
    axios.post("/user/"+userID+"/job", newJob)
      .then(res => {
        setIsSubmitting(false)
        setMessage("Job created")

      })
      .catch(err => {
          setIsSubmitting(false)
          setErrorMessage("job could not create")
          if (err.response) {
            // client received an error response (5xx, 4xx)
            setErrorMessage(err.response.data.message)
          } else if (err.request) {
            console.log(err.request)

            // client never received a response, or request never left
          } else {
            console.log(err)
            // anything else
          }
        }
      )

  }
  const titleValueChangeHandler=(event)=>{
    setErrorMessage("")
    setTitle(event.target.value)
  }
  const captionValueChangeHandler=(event)=>{
    setErrorMessage("")
    setCaption(event.target.value)
  }
  const categoryChangeHandler=(event)=>{
    setErrorMessage("")
    setCategory(event.target.value);
  }
  const salaryValueChangeHandler=(event)=>{
    setErrorMessage("")
     const numberString= Number(event.target.value)
      if(numberString<=0){
        return
      }
      setSalary(numberString)
  }

  useEffect(()=>{
   setLoggedIn(authContext.isAuth)
  })
  useEffect(()=>{
    if (isLoggedIn){
      setContent(    <Page
        className={Style.center}
        title="Create New Job"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm" className={Style.center}>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Post new Job!
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Volunteers could choose this job.
              </Typography>
            </Box>
            {newJob.image===""?null:   <Avatar src={image} variant="square" style={{    width: 80,
              height: 80,marginRight:"auto",marginLeft:"auto"}}>A</Avatar>}
            <TextField
              required
              fullWidth
              label="Title"
              margin="normal"
              name="title"
              onChange={titleValueChangeHandler}
              variant="outlined"
            />
            <TextField
              fullWidth
              required
              label="Caption"
              margin="normal"
              name="caption"
              multiline
              rows={2}
              onChange={captionValueChangeHandler}
              variant="outlined"
            />
            <FormHelperText >
              You should have credit in your account
            </FormHelperText>

            <TextField
              required
              fullWidth
              label="Salary"
              margin="normal"
              name="salary"
              variant="outlined"
              type="number"
              onChange={salaryValueChangeHandler}
            />
            <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                onChange={categoryChangeHandler}
              >
                <MenuItem value={"General"}>General</MenuItem>
                <MenuItem value={"Animal"}>Animal</MenuItem>
                <MenuItem value={"Programing"}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <UploadJobImage   uploadHandler={uploadHandler}></UploadJobImage>

            <Box
              alignItems="center"
              display="flex"
              ml={-1}
            >
            </Box>
            <FormHelperText error>
              {errorMessage}
            </FormHelperText>
            <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
              <b> {message}</b>
            </FormHelperText>
            <Box my={2}>
              <Button
                color="primary"
                onClick={submitJob}
                fullWidth
                size="large"
                type="submit"
                variant="contained"

              >
                Post Job
              </Button>
            </Box>
            {isSubmitting? <LinearProgress />:null}
            <Typography
              color="textSecondary"
              variant="body1"
            >
              You want see job list?
              {' '}
              <Link
                component={RouterLink}
                to="/job/list"
                variant="h6"
              >
                Job List
              </Link>
            </Typography>


          </Container>
        </Box>
      </Page>)
    }else {
     setContent( <Page
         className={Style.center}
         title="Login Required"
       >
       <Box mb={3}>
         <Typography
           color="textPrimary"
           variant="h2"
         >
           You are not logged in!
         </Typography>
         <Typography
           color="textSecondary"
           gutterBottom
           variant="body2"
         >
           Please Register if you didn't yet!
         </Typography>
       </Box>
       </Page>
     )
    }
  },[isLoggedIn,isSubmitting,message,errorMessage,image,title,caption,category,salary])

  return (
  <div>
    {content}
  </div>
  );
}
export default JobNew;