import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
    btnClass:{
      margin:10,

    },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const JobCard = ({ className, job, ...rest }) => {
  const classes = useStyles();
  const navigate=useNavigate();

  return (
    <Card className={clsx(classes.root, className)}
      {...rest}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          mb={3}
        >
          <Avatar
            className={classes.large}
            alt="Product"
            src={job.image}
            variant="square"
          />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          variant="h3"
        >
          {job.title}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          variant="h6"
        >
          {job.salary}
          {' '}
          $
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
            {job.caption}
        </Typography>
      </CardContent>

      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            <AccessTimeIcon
              className={classes.statsIcon}
              color="action"
            />
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              {job.created}
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            {/*<GetAppIcon*/}
            {/*  className={classes.statsIcon}*/}
            {/*  color="action"*/}
            {/*/>*/}

            <Button variant="outlined" color="primary" onClick={()=>{
              navigate("/job/"+job.job_id,{ state: job })
            }}>
              See more
            </Button>
          </Grid>

        </Grid>
      </Box>
    </Card>
  );
};

JobCard.propTypes = {
  className: PropTypes.string,
  job: PropTypes.object.isRequired
};

export default JobCard;
