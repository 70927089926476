
import React, { useEffect, useState } from 'react';
import Page from "../../../components/Page";
import {Box, Button, Container, FormHelperText, Link, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UploadCause from "../../../components/uploadCause";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Link as RouterLink} from "react-router-dom";
import Style from '../style.module.css';
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";

export default function GrantBasic(props){
  const locations =[
    { title:"Alaska" },
    { title: "Anchorage" },
    { title: "Fairbanks" },
    { title: "Alabama" },
    { title: "Anniston" },
    { title: "Auburn" },
    { title: "Birmingham" },
    { title: "Columbus, GA" },
    { title: "Daphne" },
    { title: "Decatur" },
    { title: "Dothan" },
    { title: "Florence" },
    { title: "Gadsden" },
    { title: "Huntsville" },
    { title: "Mobile" },
    { title: "Montgomery" },
    { title: "Tuscaloosa" },
    { title: "Arkansas" },
    { title: "Fayetteville" },
    { title: "Fort Smith" },
    { title: "Hot Springs" },
    { title: "Jonesboro" },
    { title: "Little Rock" },
    { title: "Memphis, TN" },
    { title: "Pine Bluff" },
    { title: "Texarkana, TX" },
    { title: "Arizona" },
    { title: "Flagstaff" },
    { title: "Lake Havasu City" },
    { title: "Phoenix" },
    { title: "Prescott Valley" },
    { title: "Salt Lake City, UT" },
    { title: "Sierra Vista" },
    { title: "Tucson" },
    { title: "Yuma" },
    { title: "California" },
    { title: "Bakersfield" },
    { title: "Chico" },
    { title: "El Centro" },
    { title: "Fresno" },
    { title: "Hanford" },
    { title: "Los Angeles" },
    { title: "Madera" },
    { title: "Merced" },
    { title: "Modesto" },
    { title: "Napa" },
    { title: "Oxnard" },
    { title: "Redding" },
    { title: "Riverside" },
    { title: "Sacramento" },
    { title: "Salinas" },
    { title: "San Diego" },
    { title: "San Francisco" },
    { title: "San Jose" },
    { title: "San Luis Obispo" },
    { title: "Santa Cruz" },
    { title: "Santa Maria" },
    { title: "Santa Rosa" },
    { title: "Stockton" },
    { title: "Vallejo" },
    { title: "Visalia" },
    { title: "Yuba City" },
    { title: "Colorado" },
    { title: "Anchorage, AK" },
    { title: "Boulder" },
    { title: "Colorado Springs" },
    { title: "Denver" },
    { title: "Fort Collins" },
    { title: "Grand Junction" },
    { title: "Greeley" },
    { title: "Pueblo" },
    { title: "Connecticut" },
    { title: "Boston, MA" },
    { title: "Bridgeport" },
    { title: "Lansing" },
    { title: "New Haven" },
    { title: "New York City, NY" },
    { title: "Norwich" },
    { title: "Worcester, MA" },
    { title: "District of Columbia" },
    { title: "Washington DC" },
    { title: "Delaware" },
    { title: "Dover" },
    { title: "Philadelphia, PA" },
    { title: "Salisbury, MD" },
    { title: "Florida" },
    { title: "Cape Coral" },
    { title: "Crestview" },
    { title: "Deltona" },
    { title: "Gainesville" },
    { title: "Homosassa Springs" },
    { title: "Jacksonville" },
    { title: "Lakeland" },
    { title: "Miami" },
    { title: "Naples" },
    { title: "North Port" },
    { title: "Ocala" },
    { title: "Orlando" },
    { title: "Palm Bay" },
    { title: "Panama City" },
    { title: "Pensacola" },
    { title: "Port St. Lucie" },
    { title: "Punta Gorda" },
    { title: "Sebastian" },
    { title: "Sebring" },
    { title: "Tallahassee" },
    { title: "Tampa" },
    { title: "The Villages" },
    { title: "Washington DC" },
    { title: "Georgia" },
    { title: "Albany" },
    { title: "Athens" },
    { title: "Atlanta" },
    { title: "Augusta" },
    { title: "Brunswick" },
    { title: "Chattanooga, TN" },
    { title: "Columbus" },
    { title: "Dalton" },
    { title: "Gainesville" },
    { title: "Hinesville" },
    { title: "Macon" },
    { title: "Rome" },
    { title: "Savannah" },
    { title: "Valdosta" },
    { title: "Warner Robins" },
    { title: "Guam" },
    { title: "Hawaii" },
    { title: "Kahului" },
    { title: "Urban Honolulu" },
    { title: "Iowa" },
    { title: "Ames" },
    { title: "Cedar Rapids" },
    { title: "Davenport" },
    { title: "Des Moines" },
    { title: "Dubuque" },
    { title: "Iowa City" },
    { title: "Omaha, NE" },
    { title: "Sioux City" },
    { title: "Waterloo" },
    { title: "Idaho" },
    { title: "Boise City" },
    { title: "Coeur d'Alene" },
    { title: "Idaho Falls" },
    { title: "Lewiston" },
    { title: "Logan, UT" },
    { title: "Pocatello" },
    { title: "Twin Falls" },
    { title: "Illinois" },
    { title: "Bloomington" },
    { title: "Cape Girardeau, MO" },
    { title: "Carbondale" },
    { title: "Champaign" },
    { title: "Chicago" },
    { title: "Danville" },
    { title: "Davenport, IA" },
    { title: "Decatur" },
    { title: "Kankakee" },
    { title: "New Orleans, LA" },
    { title: "Peoria" },
    { title: "Rockford" },
    { title: "San Antonio, TX" },
    { title: "Springfield" },
    { title: "St. Louis, MO" },
    { title: "Indiana" },
    { title: "Baltimore, MD" },
    { title: "Bloomington" },
    { title: "Chicago, IL" },
    { title: "Cincinnati, OH" },
    { title: "Columbus" },
    { title: "Elkhart" },
    { title: "Evansville" },
    { title: "Fort Wayne" },
    { title: "Indianapolis" },
    { title: "Kokomo" },
    { title: "Lafayette" },
    { title: "Louisville, KY" },
    { title: "Michigan City" },
    { title: "Muncie" },
    { title: "South Bend" },
    { title: "Terre Haute" },
    { title: "Kansas" },
    { title: "Kansas City, MO" },
    { title: "Lawrence" },
    { title: "Manhattan" },
    { title: "St. Joseph, MO" },
    { title: "Topeka" },
    { title: "Wichita" },
    { title: "Kentucky" },
    { title: "Bowling Green" },
    { title: "Carbondale, IL" },
    { title: "Cincinnati, OH" },
    { title: "Clarksville, TN" },
    { title: "Elizabethtown" },
    { title: "Evansville, IN" },
    { title: "Huntington, WV" },
    { title: "Lexington" },
    { title: "Louisville" },
    { title: "Owensboro" },
    { title: "Louisiana" },
    { title: "Alexandria" },
    { title: "Baton Rouge" },
    { title: "Hammond" },
    { title: "Houma" },
    { title: "Lafayette" },
    { title: "Lake Charles" },
    { title: "Monroe" },
    { title: "New Orleans" },
    { title: "Shreveport" },
    { title: "Massachusetts" },
    { title: "Barnstable Town" },
    { title: "Boston" },
    { title: "Pittsfield" },
    { title: "Portland, ME" },
    { title: "Providence, RI" },
    { title: "Springfield" },
    { title: "Worcester" },
    { title: "Maryland" },
    { title: "Baltimore" },
    { title: "California" },
    { title: "Cumberland" },
    { title: "Hagerstown" },
    { title: "Philadelphia, PA" },
    { title: "Salisbury" },
    { title: "Washington DC" },
    { title: "Maine" },
    { title: "Bangor" },
    { title: "Lewiston" },
    { title: "Portland" },
    { title: "Michigan" },
    { title: "Ann Arbor" },
    { title: "Battle Creek" },
    { title: "Bay City" },
    { title: "Detroit" },
    { title: "Flint" },
    { title: "Grand Rapids" },
    { title: "Hartford" },
    { title: "Jackson" },
    { title: "Kalamazoo" },
    { title: "Midland" },
    { title: "Monroe" },
    { title: "Muskegon" },
    { title: "Niles" },
    { title: "Saginaw" },
    { title: "South Bend, IN" },
    { title: "Washington DC" },
    { title: "Minnesota" },
    { title: "Duluth" },
    { title: "Fargo, ND" },
    { title: "Grand Forks, ND" },
    { title: "La Crosse, WI" },
    { title: "Mankato" },
    { title: "Minneapolis" },
    { title: "Rochester" },
    { title: "St. Cloud" },
    { title: "Missouri" },
    { title: "Cape Girardeau" },
    { title: "Columbia" },
    { title: "Jefferson City" },
    { title: "Joplin" },
    { title: "Kansas City" },
    { title: "Springfield" },
    { title: "Springfield, IL" },
    { title: "St. Joseph" },
    { title: "St. Louis" },
    { title: "Washington DC" },
    { title: "Mississippi" },
    { title: "Gulfport" },
    { title: "Hattiesburg" },
    { title: "Jackson" },
    { title: "Memphis, TN" },
    { title: "Montana" },
    { title: "Billings" },
    { title: "Great Falls" },
    { title: "Missoula" },
    { title: "North Carolina" },
    { title: "Asheville" },
    { title: "Baton Rouge, LA" },
    { title: "Burlington" },
    { title: "Charlotte" },
    { title: "Durham" },
    { title: "Fayetteville" },
    { title: "Goldsboro" },
    { title: "Greensboro" },
    { title: "Greenville" },
    { title: "Hickory" },
    { title: "Jacksonville" },
    { title: "Myrtle Beach, SC" },
    { title: "New Bern" },
    { title: "Raleigh" },
    { title: "Rocky Mount" },
    { title: "Virginia Beach, VA" },
    { title: "Washington DC" },
    { title: "Wilmington" },
    { title: "Winston-Salem" },
    { title: "North Dakota" },
    { title: "Bismarck" },
    { title: "Fargo" },
    { title: "Grand Forks" },
    { title: "Nebraska" },
    { title: "Grand Island" },
    { title: "Lincoln" },
    { title: "Omaha" },
    { title: "Reno, NV" },
    { title: "Sioux City, IA" },
    { title: "Washington DC" },
    { title: "New Hampshire" },
    { title: "Boston, MA" },
    { title: "Manchester" },
    { title: "Portland, ME" },
    { title: "New Jersey" },
    { title: "Allentown, PA" },
    { title: "Atlantic City" },
    { title: "Binghamton, NY" },
    { title: "New York City, NY" },
    { title: "Ocean City" },
    { title: "Philadelphia, PA" },
    { title: "Trenton" },
    { title: "Vineland" },
    { title: "New Mexico" },
    { title: "Albuquerque" },
    { title: "Farmington" },
    { title: "Las Cruces" },
    { title: "Santa Fe" },
    { title: "Nevada" },
    { title: "Carson City" },
    { title: "Las Vegas" },
    { title: "Reno" },
    { title: "New York" },
    { title: "Albany" },
    { title: "Binghamton" },
    { title: "Buffalo" },
    { title: "Elmira" },
    { title: "Glens Falls" },
    { title: "Ithaca" },
    { title: "Kingston" },
    { title: "New York City" },
    { title: "Poughkeepsie" },
    { title: "Rochester" },
    { title: "Syracuse" },
    { title: "Utica" },
    { title: "Washington DC" },
    { title: "Watertown" },
    { title: "Ohio" },
    { title: "Akron" },
    { title: "Canton" },
    { title: "Cincinnati" },
    { title: "Cleveland" },
    { title: "Columbus" },
    { title: "Dayton" },
    { title: "Huntington, WV" },
    { title: "Lima" },
    { title: "Mansfield" },
    { title: "Springfield" },
    { title: "Toledo" },
    { title: "Washington DC" },
    { title: "Weirton, WV" },
    { title: "Wheeling, WV" },
    { title: "Youngstown" },
    { title: "Oklahoma" },
    { title: "Enid" },
    { title: "Fort Smith, AR" },
    { title: "Lawton" },
    { title: "Oklahoma City" },
    { title: "Tulsa" },
    { title: "Oregon" },
    { title: "Albany" },
    { title: "Bend" },
    { title: "Corvallis" },
    { title: "Eugene" },
    { title: "Grants Pass" },
    { title: "Medford" },
    { title: "Portland" },
    { title: "Salem" },
    { title: "Pennsylvania" },
    { title: "Allentown" },
    { title: "Altoona" },
    { title: "Bloomsburg" },
    { title: "Chambersburg" },
    { title: "East Stroudsburg" },
    { title: "Erie" },
    { title: "Gettysburg" },
    { title: "Harrisburg" },
    { title: "Johnstown" },
    { title: "Lancaster" },
    { title: "Lebanon" },
    { title: "New York City, NY" },
    { title: "Philadelphia" },
    { title: "Pittsburgh" },
    { title: "Reading" },
    { title: "Scranton" },
    { title: "State College" },
    { title: "Williamsport" },
    { title: "York" },
    { title: "Youngstown, OH" },
    { title: "Puerto Rico" },
    { title: "Aguadilla" },
    { title: "Arecibo" },
    { title: "Guayama" },
    { title: "Mayaguez" },
    { title: "Ponce" },
    { title: "San German" },
    { title: "San Juan" },
    { title: "Yauco" },
    { title: "Rhode Island" },
    { title: "Providence" },
    { title: "South Carolina" },
    { title: "Augusta, GA" },
    { title: "Charleston" },
    { title: "Charlotte, NC" },
    { title: "Columbia" },
    { title: "Florence" },
    { title: "Greenville" },
    { title: "Hilton Head Island" },
    { title: "Myrtle Beach" },
    { title: "Spartanburg" },
    { title: "Sumter" },
    { title: "South Dakota" },
    { title: "Rapid City" },
    { title: "Sioux City, IA" },
    { title: "Sioux Falls" },
    { title: "Tennessee" },
    { title: "Chattanooga" },
    { title: "Clarksville" },
    { title: "Cleveland" },
    { title: "Jackson" },
    { title: "Johnson City" },
    { title: "Kingsport" },
    { title: "Knoxville" },
    { title: "Memphis" },
    { title: "Morristown" },
    { title: "Nashville" },
    { title: "Texas" },
    { title: "Abilene" },
    { title: "Amarillo" },
    { title: "Austin" },
    { title: "Beaumont" },
    { title: "Brownsville" },
    { title: "College Station" },
    { title: "Corpus Christi" },
    { title: "Dallas" },
    { title: "El Paso" },
    { title: "Houston" },
    { title: "Killeen" },
    { title: "Laredo" },
    { title: "Longview" },
    { title: "Lubbock" },
    { title: "McAllen" },
    { title: "Midland" },
    { title: "Odessa" },
    { title: "San Angelo" },
    { title: "San Antonio" },
    { title: "Sherman" },
    { title: "Texarkana" },
    { title: "Tyler" },
    { title: "Victoria" },
    { title: "Waco" },
    { title: "Wichita Falls" },
    { title: "Utah" },
    { title: "Logan" },
    { title: "Ogden" },
    { title: "Provo" },
    { title: "Salt Lake City" },
    { title: "St. George" },
    { title: "Virginia" },
    { title: "Blacksburg" },
    { title: "Charlottesville" },
    { title: "Harrisonburg" },
    { title: "Kingsport, TN" },
    { title: "Los Angeles, CA" },
    { title: "Lynchburg" },
    { title: "Richmond" },
    { title: "Roanoke" },
    { title: "Staunton" },
    { title: "Virginia Beach" },
    { title: "Washington DC" },
    { title: "Winchester" },
    { title: "Virgin Islands" },
    { title: "Vermont" },
    { title: "Burlington" },
    { title: "Washington" },
    { title: "Bellingham" },
    { title: "Bremerton" },
    { title: "Kennewick" },
    { title: "Lewiston, ID" },
    { title: "Longview" },
    { title: "Mount Vernon" },
    { title: "Olympia" },
    { title: "Portland, OR" },
    { title: "Seattle" },
    { title: "Spokane" },
    { title: "Walla Walla" },
    { title: "Wenatchee" },
    { title: "Yakima" },
    { title: "Wisconsin" },
    { title: "Appleton" },
    { title: "Chicago, IL" },
    { title: "Duluth, MN" },
    { title: "Eau Claire" },
    { title: "Fond du Lac" },
    { title: "Green Bay" },
    { title: "Janesville" },
    { title: "La Crosse" },
    { title: "Madison" },
    { title: "Milwaukee" },
    { title: "Minneapolis, MN" },
    { title: "Oshkosh" },
    { title: "Racine" },
    { title: "Sheboygan" },
    { title: "Wausau" },
    { title: "West Virginia" },
    { title: "Beckley" },
    { title: "Charleston" },
    { title: "Cumberland, MD" },
    { title: "Hagerstown, MD" },
    { title: "Huntington" },
    { title: "Morgantown" },
    { title: "Parkersburg" },
    { title: "Washington DC" },
    { title: "Weirton" },
    { title: "Wheeling" },
    { title: "Winchester, VA" },
    { title: "Wyoming" },
    { title: "Casper" },
    { title: "Cheyenne" }
  ]
  const categories = [{ title:"Arts, culture, and humanities nonprofits" },
    { title:"Organizations supporting an arts, culture, or humanities nonprofit" },
    { title:"Organizations supporting multiple arts, culture, or humanities nonprofits" },
    { title:"Arts and culture promoters" },
    { title:"Cultural awareness organizations" },
    { title:"Arts education nonprofits" },
    { title:"Arts councils" },
    { title:"Media organizations" },
    { title:"Film production organizations" },
    { title:"Television broadcasters" },
    { title:"Nonprofit publishers" },
    { title:"Radio broadcasters" },
    { title:"Visual arts nonprofits" },
    { title:"Museums" },
    { title:"Art museums" },
    { title:"Children's museums" },
    { title:"History museums" },
    { title:"Natural history museums" },
    { title:"Science museums" },
    { title:"Performing arts organizations" },
    { title:"Performing arts centers" },
    { title:"Dance organizations" },
    { title:"Ballet companies" },
    { title:"Theaters" },
    { title:"Music festivals and events" },
    { title:"Symphony orchestras" },
    { title:"Operas" },
    { title:"Performing arts schools" },
    { title:"Humanities organizations" },
    { title:"Historical societies" },
    { title:"Arts services organizations" },
    { title:"Educational institutions" },
    { title:"School foundations" },
    { title:"Organizations supporting multiple schools" },
    { title:"Elementary and secondary schools" },
    { title:"Preschools" },
    { title:"Elementary schools" },
    { title:"High schools" },
    { title:"Special ed schools" },
    { title:"Charter schools" },
    { title:"Vocational and technical schools" },
    { title:"Colleges and universities" },
    { title:"Community colleges" },
    { title:"Colleges" },
    { title:"Universities" },
    { title:"Professional schools" },
    { title:"Adult education centers" },
    { title:"Libraries" },
    { title:"Student service providers" },
    { title:"Scholarship organizations" },
    { title:"Fraternities and sororities" },
    { title:"Alumni associations" },
    { title:"Educational service providers" },
    { title:"Remedial reading organizations" },
    { title:"PTAs" },
    { title:"Environmental organizations" },
    { title:"Organization supporting an environmental nonprofit" },
    { title:"Organizations supporting multiple environmental nonprofits" },
    { title:"Pollution abatement organizations" },
    { title:"Recycling programs" },
    { title:"Natural resource conservation nonprofits" },
    { title:"Water and wetland protection organizations" },
    { title:"Land conservation groups" },
    { title:"Energy conservation organizations" },
    { title:"Botanical and horticultural nonprofits" },
    { title:"Botanical gardens" },
    { title:"Garden clubs" },
    { title:"Civic environmental organizations" },
    { title:"Environmental education programs" },
    { title:"Animal organizations" },
    { title:"Organizations supporting an animal nonprofit" },
    { title:"Organizations supporting multiple animal nonprofits" },
    { title:"Animal shelters" },
    { title:"Wildlife protection organizations" },
    { title:"Endangered species organizations" },
    { title:"Wildlife sanctuaries" },
    { title:"Veterinarians" },
    { title:"Zoos" },
    { title:"Animal services organizations" },
    { title:"Health organizations" },
    { title:"Organizations supporting a health care nonprofit" },
    { title:"Organizations supporting multiple health care nonprofits" },
    { title:"Hospitals" },
    { title:"Hospital systems" },
    { title:"General hospitals" },
    { title:"Specialty hospitals" },
    { title:"Outpatient clinics" },
    { title:"Managed care organizations" },
    { title:"Community clinics" },
    { title:"Reproductive health facilities" },
    { title:"Family planning centers" },
    { title:"Rehabilitation centers" },
    { title:"Ancillary health service organizations" },
    { title:"Ambulances and EMTs" },
    { title:"Organ banks" },
    { title:"Public health programs" },
    { title:"Health financing and support organizations" },
    { title:"Health finance organizations" },
    { title:"Patient services organizations" },
    { title:"Nursing care organizations" },
    { title:"Nursing homes" },
    { title:"Home health care agencies" },
    { title:"Mental health organizations" },
    { title:"Organizations supporting a mental health nonprofit" },
    { title:"Organizations supporting multiple mental health nonprofits" },
    { title:"Substance abuse organizations" },
    { title:"Mental health centers" },
    { title:"Community mental health centers" },
    { title:"Mental health group homes" },
    { title:"Crisis hotlines" },
    { title:"Support groups" },
    { title:"Mental health associations" },
    { title:"Disease-focused nonprofits" },
    { title:"Organizations supporting a disease-focused nonprofit" },
    { title:"Organizations supporting multiple disease-focused nonprofits" },
    { title:"Genetic disorder organizations" },
    { title:"Cancer organizations" },
    { title:"Organ disease organizations" },
    { title:"Heart disease organizations" },
    { title:"Kidney disease organizations" },
    { title:"Nerve, muscle, and bone disease organizations" },
    { title:"Specific disease focused organizations" },
    { title:"HIV / AIDS organizations" },
    { title:"Alzheimer's organizations" },
    { title:"Autism organizations" },
    { title:"Medical discipline organizations" },
    { title:"Medical research organizations" },
    { title:"Organizations supporting a medical research nonprofit" },
    { title:"Organizations supporting multiple medical research nonprofits" },
    { title:"Cancer research centers" },
    { title:"Nerve, muscle, and bone research centers" },
    { title:"Medical practice research organizations" },
    { title:"Crime and legal aid organizations" },
    { title:"Organizations supporting a crime or legal aid nonprofit" },
    { title:"Organizations supporting multiple crime and legal aid nonprofits" },
    { title:"Crime prevention programs" },
    { title:"Offender rehabilitation programs" },
    { title:"Abuse and exploitation organizations" },
    { title:"Child abuse organizations" },
    { title:"Legal aid societies" },
    { title:"Unions and employment organizations" },
    { title:"Organizations supporting a union or employment organization" },
    { title:"Organizations supporting muiltiple union and employment nonprofits" },
    { title:"Employment agencies and job training programs" },
    { title:"Job training programs" },
    { title:"Vocational rehabilitation programs" },
    { title:"Unions" },
    { title:"Food and agriculture nonprofits" },
    { title:"Organizations supporting a food or agriculture nonprofit" },
    { title:"Organizations supporting multiple food and agriculture nonprofits" },
    { title:"Agricultural organizations" },
    { title:"Food distribution programs" },
    { title:"Food banks" },
    { title:"Housing providers and shelters" },
    { title:"Organizations supporting a housing provider or shelter" },
    { title:"Organizations supporting multiple housing providers and shelters" },
    { title:"Housing development and management companies" },
    { title:"Public housing agencies" },
    { title:"Retirement homes" },
    { title:"Housing rehabilitation programs" },
    { title:"Housing search organizations" },
    { title:"Temporary housing shelters" },
    { title:"Homeless shelters" },
    { title:"Homeowner and tenant associations" },
    { title:"Home repair programs" },
    { title:"Public safety organizations" },
    { title:"Organizations supporting a public safety organization" },
    { title:"Organizations supporting multiple public safety organizations" },
    { title:"Disaster relief organizations" },
    { title:"Search and rescue organizations" },
    { title:"Volunteer fire departments" },
    { title:"Safety education programs" },
    { title:"Recreation, sports, and social clubs" },
    { title:"Organizations supporting a recreation, sport, or social club" },
    { title:"Organizations supporting multiple recreation, sports, and social clubs" },
    { title:"Summer camps" },
    { title:"Community parks and recreation centers" },
    { title:"Community recreation centers" },
    { title:"Parks and playgrounds" },
    { title:"Training camps" },
    { title:"Social and country clubs" },
    { title:"Fairs" },
    { title:"Amateur sports clubs" },
    { title:"Hunting and fishing clubs" },
    { title:"Baseball leagues" },
    { title:"Soccer leagues" },
    { title:"Football leagues" },
    { title:"Tennis leagues" },
    { title:"Swimming clubs" },
    { title:"Hockey, skate, and ski clubs" },
    { title:"Golf courses" },
    { title:"Sports competitions" },
    { title:"Professional sports leagues" },
    { title:"Youth development organizations" },
    { title:"Organizations supporting a youth development nonprofit" },
    { title:"Organizations supporting multiple youth development nonprofits" },
    { title:"Boys and girls clubs" },
    { title:"Adult / child mentoring programs" },
    { title:"Scouting organizations" },
    { title:"Youth development programs" },
    { title:"Youth agriculture organizations" },
    { title:"Religous youth groups" },
    { title:"Human service organizations" },
    { title:"Organizations supporting a human services nonprofit" },
    { title:"Organizations supporting multiple human services nonprofits" },
    { title:"Multiservice human service organizations" },
    { title:"Youth service organizations" },
    { title:"Adoption agencies" },
    { title:"Foster care systems" },
    { title:"Day care centers" },
    { title:"Family service centers" },
    { title:"Domestic violence shelters" },
    { title:"Personal service organizations" },
    { title:"Emergency assistance programs" },
    { title:"Residential care facilities" },
    { title:"Hospices" },
    { title:"Senior residential care facilities" },
    { title:"Specific population service providers" },
    { title:"Senior centers" },
    { title:"Developmentally disabled centers" },
    { title:"Immigrant centers" },
    { title:"homeless assistance program" },
    { title:"International-focused organizations" },
    { title:"Organizations supporting an international-focused nonprofit" },
    { title:"Organizations supporting multiple international-focused nonprofits" },
    { title:"Foreign exchanges" },
    { title:"International development organizations" },
    { title:"International humanitarian organizations" },
    { title:"World peace organizations" },
    { title:"International human rights organizations" },
    { title:"Refugee organizations" },
    { title:"Civil rights and social justice organizations" },
    { title:"Organizations supporting a civil rights or social justice nonprofit" },
    { title:"Organizations supporting multiple civil rights and social justice nonprofits" },
    { title:"Civil rights organizations" },
    { title:"Women's rights groups" },
    { title:"LGBT rights organizations" },
    { title:"Voter registration groups" },
    { title:"Civil liberties organizations" },
    { title:"Pro-choice organizations" },
    { title:"Pro-life organizations" },
    { title:"Business and community development organizations" },
    { title:"Organizations supporting a business or community development nonprofit" },
    { title:"Organizations supporting multiple business and community development nonprofits" },
    { title:"Community development corporations" },
    { title:"Economic development organizations" },
    { title:"Urban development organizations" },
    { title:"Visitor and convention bureaus" },
    { title:"Business and industry associations" },
    { title:"Trade / professional associations" },
    { title:"Small business bureaus" },
    { title:"Real estate associations" },
    { title:"Nonprofit management centers" },
    { title:"Community service centers" },
    { title:"Foundations" },
    { title:"Organizations supporting a foundation" },
    { title:"Organizations supporting multiple foundations" },
    { title:"Private foundations" },
    { title:"Corporate foundations" },
    { title:"Public foundations" },
    { title:"Community foundations" },
    { title:"Scientific research centers" },
    { title:"Organizations supporting a scientific research center" },
    { title:"Organizations supporting multiple scientific research centers" },
    { title:"Physical and earth sciences research centers" },
    { title:"Engineering and technology research centers" },
    { title:"Biological research centers" },
    { title:"Social science research centers" },
    { title:"Organizations supporting a social science research center" },
    { title:"Organizations supporting multiple social science research centers" },
    { title:"Social science institutes" },
    { title:"Public sector, public co-ops, and veteran organizations" },
    { title:"Organizations supporting a public co-op or veteran organization" },
    { title:"Organizations supporting multiple public co-ops and veteran organizations" },
    { title:"Public sector improvement organizations" },
    { title:"Veteran organizations" },
    { title:"Telephone co-ops" },
    { title:"Credit unions" },
    { title:"Public utilities" },
    { title:"Consumer protection organizations" },
    { title:"Religious organizations" },
    { title:"Organizations supporting a religious nonprofit" },
    { title:"Organizations supporting multiple religious nonprofits" },
    { title:"Christian organizations" },
    { title:"Catholic organizations" },
    { title:"Jewish organizations" },
    { title:"Islamic organizations" },
    { title:"Buddhist organizations" },
    { title:"Religious media organizations" },
    { title:"Mutual assistance organizations" },
    { title:"Organizations supporting a mutual assistance organization" },
    { title:"Organizations supporting multiple mutual assistance organizations" },
    { title:"Insurance companies" },
    { title:"Pension and retirement funds" },
    { title:"Fraternal societies" },
    { title:"Cemeteries"}
  ]
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
  }));
  const classes = useStyles();
  const [causeAttribute,setCauseAttribute]=useState({
    tin:"",
    email:"",
    category:"",
    location:""
  })

  const [category,setCategory]=useState("");
  const [location,setLocation]=useState("")
  const [message,setMessage]=useState('')
  const [errorMessage,setErrorMessage]=useState("");
  const [errorHelper,serErrorHelper]=useState('Please enter your email address.');
  const [tinHelper,setTinHelper]=useState('Taxpayer Identification Numbers (TIN)');
  const [emailValid,setEmailValid] = useState(false);
  const [tinValid,setTinValid] = useState(false);

  const emailChangeHandler=(event)=>{

     let { name, value } = event.target;
     setCauseAttribute({
       ...causeAttribute,
       [name]:value
     })
    setTimeout(
      () =>
      {
          if(!validateEmail(value)){
            setEmailValid(true);
            serErrorHelper("Email is not valid.")
          }else {
            setEmailValid(false);
            props.valueChangeHandler(name,value);
            serErrorHelper("")
          }


      },
      800
    );
  }

  const tinChangeHandler=(event)=>{

    let { name, value } = event.target;
    setCauseAttribute({
      ...causeAttribute,
      [name]:value
    })
    setTimeout(
      () =>
      {
          if(!validateSSNorTIN(value)){
            console.log(value)
            setTinValid(true);
            setTinHelper("Tin number is not valid")
          }else {
            setTinValid(false);
            setTinHelper("")
            props.valueChangeHandler(name,value);

          }

      },
      800
    );
  }
  function categoryChangeHandler(event,value) {
    setErrorMessage("")
    setMessage("")
    if (value){
      setCategory(value.title)
      props.valueChangeHandler('category',value.title);
    }
  }

  const defaultLocations = {
    options: locations,
    getOptionLabel: (option) => option.title,
  };
  const defaultCategories = {
    options: categories,
    getOptionLabel: (option) => option.title,
  };
  function onLocationChanged(event,value) {
    if (value){
      setLocation(value.title)
      props.valueChangeHandler('location',value.title);
    }
  }

  function onCategoryChanged(event,value) {
    if (value){
      setCategory(value.title)
      props.valueChangeHandler('category',value);
    }
  }
  function validateTin(tin){
    var ssnOrTinRegex = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/;
    return ssnOrTinRegex.test(tin)

  }
  function validateSSNorTIN(data) {
    var ssn = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
    var tin = /^\d{2}-?\d{7}$|^XX-XXXXXXX$/;

    if (ssn.test(data)||tin.test(data)) {
      return true;
    } else {
      return false;

    }
  }
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  useEffect(()=>{
    setCauseAttribute(props.cause)
    console.log(props.cause)
  },[props.cause])

  return(
    <Page
      className={Style.center}
      title="Create Cause"
  >
    <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
    >
      <Container maxWidth="sm" className={Style.center}>
        <Box mb={3}>
          <Typography
              color="textPrimary"
              variant="h2"
          >
            Basic Information
          </Typography>
          <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
          >
            Users could choose this cause.
          </Typography>
        </Box>
          <TextField
            required
            fullWidth
            label="Email"
            margin="normal"
            name="email"
            onChange={emailChangeHandler}
            value={causeAttribute['email']}
            variant="outlined"
            helperText={errorHelper}
            error={emailValid}

          />
        <TextField
            fullWidth
            label="TIN"
            margin="normal"
            name="tin"
            onChange={tinChangeHandler}
            value={causeAttribute['tin']}
            variant="outlined"
            helperText={tinHelper}
            error={tinValid}

        />
        <Autocomplete
          onChange={onLocationChanged}

          {...defaultLocations}
          id="location-select"
          defaultValue={causeAttribute['location']}
          renderInput={(params) =>
            <TextField                     variant="outlined"
                                           {...params}
                                           label="Select Location" margin="normal" />}
        />
        <Autocomplete
          onChange={categoryChangeHandler}
          defaultValue={causeAttribute['category']}

          {...defaultCategories}
          id="category-select"
          renderInput={(params) =>
            <TextField                     variant="outlined"
                                           {...params}
                                           label="Select Category" margin="normal" />}
        />




      </Container>
    </Box>
  </Page>)
}