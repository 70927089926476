import React, { useState } from 'react';
import Page from "../../../components/Page";
import { Avatar, Box, Button, Container, FormHelperText, Link, Typography } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UploadCause from "../../../components/uploadCause";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Link as RouterLink} from "react-router-dom";
import Style from '../style.module.css';
import {makeStyles} from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';

const GrantReview=(props)=>{


  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),

    },
  }));
  const classes = useStyles();

  console.log(props.cause)

  return(    <Page
    className={Style.center}
    title="Create Cause,"
  >
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm" className={Style.center}>
        <Box mb={3}>
          <Typography
            color="textPrimary"
            variant="h2"
          >
            Review submitted cause
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Please check information.
          </Typography>
        </Box>
        <div className={Style.center}>
          <Avatar variant="square" alt="Logo" src={props.cause['logo_url']?props.cause['logo_url']: "-" } className={classes.large} />
        </div>
        <br></br>
        <hr></hr>
        <br></br>

        <p> <b>Organization Name: </b>{props.cause['value']?props.cause['value']: "-" }</p>
        <p> <b>Email: </b> {props.cause['email']?props.cause['email']: "-" }</p>
        <p> <b>URL: </b> {props.cause['url_source']?props.cause['url_source']: "-" }</p>
        <p> <b>Description: </b> {props.cause['description']?props.cause['description']: "-" }</p>
        <p> <b>TIN Number: </b>{props.cause['tin']?props.cause['tin']: "-" }</p>
        <p> <b>Location: </b>{props.cause['location']?props.cause['location']: "-" }</p>
        <p> <b>Category: </b>{props.cause['category']?props.cause['category']: "-" }</p>
      </Container>
      <Divider variant="inset" style={{marginTop:"10px"}} />
      <br></br>

      <Button style={{marginTop:"10px"}} variant="outlined" color="primary" href="#outlined-buttons" onClick={props.registerHandler}>
        Register Cause
      </Button>
    </Box>

  </Page>)
}
export default GrantReview;