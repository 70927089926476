import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import axios from '../../../axios-agent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useNavigate } from 'react-router-dom';
const useStylesButton =  makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
const CauseList=()=> {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true);


  let tempCause =  {
    id: "",
    value: "Disabled American Veterans",
    key: "",
    type: 1,
    logo_url: "",
    bucket: 0,
    description: "",
    hero_image: "",
    tags: null
  };

  const [data,setData] =useState([]) ;
  const [causes,setCauseList]=useState([])
  const options = {
    rowCursorHand: true,
    selectableRowsHideCheckboxes:true,
  };
  const btnClasses = useStylesButton();
  useEffect(()=>{
    if (data.length>0){
      setLoading(false)
    }
  },[data])

  const columns = [
    {
      name: "ID",
    },
    {
      name: "Name",
      options: {
        filter: true,
      }
    },
    {
      label: "Key",
      name: "key",
      options: {
        filter: true,
      }
    },
    {
      name: "Tags",
      options: {
        filter: false,
      }
    },
    {
      name: "Type",
      options: {
        filter: true,
      }
    },
    {
      name: "Bucket",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Button
              variant="outlined"
              style={{borderColor:"green"}}
              className={btnClasses.button}
              size="small"
              onClick={()=>{showCauseDetail(dataIndex)}}
            >Edit</Button>
          );
        }
      }
    },
  ];
  function showCauseDetail(id){
    navigate('/app/cause/'+data[id][0])

  }
  //Load causes
  useEffect(()=>{
    let causeList=[]

    let tempData=[];
    axios.get('/cause').then(response => {
      let tempCauseList = response.data;
      if(tempCauseList.length>0){
        tempCauseList.map(cause=>{
        tempCause.value=cause.value;
        tempCause.key=cause.key;
        tempCause.type=cause.type;
        tempCause.bucket=cause.bucket;
        tempCause.tags=cause.tags;
        tempCause.id=cause.id;
        causeList.push(tempCause);

        //......................
          let tags="-"
          if (cause.tags!=null){
            cause.tags.map(tag=>{
              tags=tags+","+tag
            })
          }
          const dataCell=[cause.id,cause.value,cause.key,tags,cause.type,cause.bucket]
          tempData.push(dataCell)
        });
        console.log(tempData)
        setData(tempData)
        setCauseList(causeList)
      }
      console.log(response.data);
    }).catch(error => {})

    },[])

  return(
    <div style={{margin:"20px"}}>
      <MUIDataTable
        title={"Cause List"}
        data={data}
        columns={columns}
        options={options}
      />
      {loading? <LinearProgress />:null}
    </div>
   )
}
export default CauseList;