import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "../../../axios-agent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Page from "../../../components/Page";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Profile from "../../User/Detail/Profile";
import ProfileDetails from "../../User/Detail/Detail";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Image} from "semantic-ui-react";
import {Box, Button, Card, CardContent, CardHeader, Divider, FormHelperText, TextField} from "@material-ui/core";
import Style from "../../User/Detail/style.module.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CauseDropdown from "../../../utils/Cause/dropdown/CauseDropdown";
import ButtonGroup from "@material-ui/core/ButtonGroup";
const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        height: 100,
        width: 100
    }
}));
const QrEdit=()=> {
    const classes = useStyles();
    const navigate=useNavigate();
    const [isSubmitting,setIsSubmitting]=useState(false)
    const [message,setMessage]=useState('')

    function saveChangesHandler() {
        setIsSubmitting(true)
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
        axios.put('/qr/'+hash,
            values).then(response=> {
            setIsSubmitting(false)
            setMessage("QR updated")
            setSourceUrl(values.newOriginalURL)
        }).catch(responseError=>{}).then(err=>{})
    }
    const [values, setValues] = useState({
        key: '',
        newOriginalURL: ''
    });
    const { hash } = useParams()
    const [sourceUrl,setSourceUrl]=useState("Reserved")
    const [qrData,setQR]=useState()
    const [content,setContent]=useState(<div><LinearProgress />
        <LinearProgress color="secondary" /></div>)
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    useEffect(()=>{
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
        axios.get('/qr/'+hash).then(response => {
            setQR(response.data)
            setValues({
                ...values,
                ["key"]: hash
            });
            if (response.data.oldUrl!=undefined &&response.data.oldUrl!= ""){
                setSourceUrl(response.data.oldUrl);
            }
        }).catch(response=>{

        })

    },[])

    useEffect(()=>{
        if (qrData!==undefined){
            setContent(
                <Page
                    className={classes.root}
                    title="QR Detail"
                >
                    <Container maxWidth="lg">
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                lg={4}
                                md={6}
                                xs={12}
                            >
                                <Image src={qrData.QR_Url} size='medium' bordered />
                            </Grid>
                            <Grid
                                item
                                lg={8}
                                md={6}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader
                                        subheader= {qrData.newUrl}
                                        title="QR"
                                    />
                                    <Divider />

                                    <CardContent>
                                        <h3></h3>
                                        <h3>source url is: {sourceUrl}</h3>

                                        <h4>You can chane source url</h4>
                                        <Grid
                                            container
                                            spacing={3}
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="New URL"
                                                    name="newOriginalURL"
                                                    onChange={handleChange}
                                                    value={values.newOriginalURL}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                    <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
                                        <b> {message}</b>
                                    </FormHelperText>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        p={2}
                                    >
                                        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                            <Button color="green" onClick={()=>{ navigate(-1)}}>Get Back</Button>
                                            <Button disabled={isSubmitting} onClick={saveChangesHandler}>Save Changes</Button>
                                        </ButtonGroup>

                                    </Box>
                                    {isSubmitting? <LinearProgress />:null}
                                </Card>

                            </Grid>
                        </Grid>
                    </Container>
                </Page>
            )
        }
    },[qrData,sourceUrl,values])
    return(<div>
        {content}
    </div>)
}
export default QrEdit;