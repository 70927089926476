import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, Container, FormHelperText
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import axios from '../../../axios-agent';
import LinearProgress from '@material-ui/core/LinearProgress';

const types = [
  {
    value: 1,
    label: 'General'
  },
  {
    value: 2,
    label: 'Backfill'
  },
  {
    value: 3,
    label: 'Special'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const Details = (props) => {
  //name={user.first_name+" "+user.last_name} role={user.role}
  //                              cmppStatus={user.cmpp_registered} cause={user.cause}
  const classes = useStyles();
  const [message,setMessage]=useState('')
  const [isSubmitting,setIsSubmitting]=useState(false)
  const [errorMessage,setErrorMessage]=useState("");

  const [values, setValues] = useState({
    organization_name: '',
    key: '',
    description: '',
    type:1,
    url:""

  });
  function saveChangesHandler() {
    setIsSubmitting(true)
    setMessage("")
    setErrorMessage("")
    const id=props.id
    console.log(values)
    let data=values
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    axios.put('/cause/ein/'+id,
      data).then(response=> {
        setIsSubmitting(false)
      setMessage("Cause updated")
        console.log(response)
    }).catch(responseError=>{

      if( responseError.response ){
        // console.log(responseError.response.data); // => the response payload
        setIsSubmitting(false)
        setErrorMessage(responseError.response.data.message)
      }else {
        setIsSubmitting(false)
        setErrorMessage("Could not update cause")
      }
    });
    }

  const navigate=useNavigate();
  useEffect(()=>{
    console.log(props.url)
      setValues({
        organization_name: props.value,
        key: props.keyName,
        description: props.description,
        logo_url:props.logo_url,
        url:props.url_source,
        dba:props.dba
      })
  },[])
  const handleChange = (event) => {
    console.log(event.target.value)
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function deleteCause() {
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    setIsSubmitting(true)
    axios.delete("/cause/"+props.id).then(response=>{
      setIsSubmitting(false)
      setMessage("Cause deleted")
      console.log(response)
    }).catch(error=>{
      setIsSubmitting(false)
      setMessage("Cause did not delete")
      console.log(error)
    })
  }

  return (
    <form>
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Cause"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Organization Name"
                name="organization_name"
                onChange={handleChange}
                value={values.organization_name}
              />
            </Grid>
            <Grid
                item
                md={12}
                xs={12}
            >
              <TextField
                  fullWidth
                  variant="filled"
                  label="DBA"
                  name="dba"
                  onChange={handleChange}
                  value={values.dba}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Web site"
                margin="normal"
                name="url"
                onChange={handleChange}
                value={values.url}
                variant="filled"
              />

            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Description"
                margin="normal"
                name="description"
                multiline
                rows={4}
                onChange={handleChange}
                value={values.description}
                variant="filled"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
          <b> {message}</b>
        </FormHelperText>
        <FormHelperText error>
          {errorMessage}
        </FormHelperText>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
            <Button color="green" onClick={()=>{ navigate("/app/cause")}}>Get Back</Button>
            <Button color="red" disabled={isSubmitting} onClick={deleteCause}>Delete</Button>
            <Button disabled={isSubmitting} onClick={saveChangesHandler}>Save Changes</Button>
          </ButtonGroup>
        </Box>
        {isSubmitting? <LinearProgress />:null}

      </Card>
    </form>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

export default Details;
