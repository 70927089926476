import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Style from '../avatar/style.css';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles, FormHelperText
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";

import LinearProgress from "@material-ui/core/LinearProgress";
import SmallWindowBox from "../../../layouts/SmallWindowBox/SmallWindowBox";
import axios from "../../../axios-agent";

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const  Profile = (props) => {
  const [selectedFiles,setSelectedFiles] = useState(null);
  const [message,setMessage]=useState("")
  const [loading,setLoading] = useState(false)
  const [avatarUrl,setAvatarUrl] = useState('/static/images/avatars/avatar.png')
  const [avatarElement,setAvatarElement] = useState(<div>loading</div>)
  const [name,setName] = useState("")
  const navigate = useNavigate();
  const [disable,setDisable]=useState(false)
  const [avatarChanged,setChangeAvatar] = useState(false)
  const [errorMessage,setErrorMessage]=useState("")
  const [isLoggedIn,SetIsLoggedIn] = useState(false)
  const [causeKey,setCauseKey]=useState("")
  const [userid,setUserId] = useState("")

  useEffect(()=>{
    setAvatarElement(     <Avatar
        className={classes.avatar}
        src={avatarUrl}

    />)
  },[avatarUrl,avatarChanged])
  useEffect(()=>{
    const avatarUrl= localStorage.getItem('avatar');

    if (avatarUrl !== undefined && avatarUrl!==""){
      setAvatarUrl(avatarUrl)
    }

  },[])
  const inputImageChangeHandler=(event)=> {
    const userId=localStorage.getItem('userid');
    setErrorMessage("")
    setMessage("")
    const choosedFile=event.target.files[0]
    const fileName = choosedFile.name
    console.log(fileName)
    if (choosedFile!=undefined){
      setIsSubmitting(true)
      let form_data = new FormData();
      form_data.append('avatar',choosedFile );
      axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
      axios.post("/user/"+userId+"/avatar", form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
          .then(res => {
            const fileNameSaved=res.data.name
            setChangeAvatar(true)
            setMessage("Upload successful")
            setIsSubmitting(false)
            const newAvatar="https://api.care2win.com/v1/user/avatar/"+fileNameSaved
            localStorage.setItem('avatar',newAvatar);
            setAvatarUrl(newAvatar)
            ///https://api.care2win.com/v1/user/4dde692c3e56f04c1ce577069c6445c4/avatar.jpg
          })
          .catch(err => {
            setIsSubmitting(false)
            setErrorMessage("Upload failed")
            console.log(err)}
          )
    }
  }

  const handleSubmit = () => {
    if (causeKey!="" && selectedFiles!=null){

    }

  };
  const [isSubmitting,setIsSubmitting]=useState(false);


  const [isInProfile,SetIsInProfile]=useState(false)
  const classes = useStyles();
  console.log(props)
  useEffect(()=>{
    if (window.location.pathname =="/app/account"){
      SetIsInProfile(true)
    }
  },[])

  return (
    <Card>
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          {avatarElement}
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {props.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {props.email}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {props.role}
          </Typography>
          <Divider></Divider>
          {(isInProfile) ?
              <div>
                <Button
                    disabled={loading}
                    variant="outlined"
                    fullWidth
                    component="label"
                    style={{borderColor:"green"}}
                >
                  Upload Avatar
                  <input
                      type="file"
                      accept="image/jpeg"
                      disabled={disable}
                      onChange={inputImageChangeHandler}
                      hidden
                  />

                </Button>
                {isSubmitting? <LinearProgress />:null}

                <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
                  <b> {message}</b>
                </FormHelperText>
                <FormHelperText error>
                  {errorMessage}
                </FormHelperText>
              </div>: ""}
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
