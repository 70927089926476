import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import UploadCause from "../../../components/uploadCause";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,

  }
}));

const Profile = (props) => {
  const [logo,setLogo]=useState('https://u.givingforward.org/logo/gf.svg')

  useEffect(()=>{
    let tempurl="https://u.givingforward.org/logo/gf.png"
    if (props.causeLogo!=""){
      tempurl=props.causeLogo
    }
    setLogo(tempurl)
  },[])
  const uploadHandler=(address)=>{
    console.log(address)
    setLogo(address)
  };
  useEffect(()=>{
    let tempurl="https://u.givingforward.org/logo/gf.png"
    if (props.causeLogo!=""){
      tempurl=props.causeLogo
    }
    setLogo(tempurl)
  },[props.causeLogo]);
  const classes = useStyles();
  console.log(props)
  return (
    <Card>
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={logo}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {props.value}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {props.keyName}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <UploadCause   causeKey={props.ein}
                       uploadHandler={uploadHandler}
        ></UploadCause>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
