import React, { useEffect, useState } from 'react';
export const AuthContext = React.createContext({
  isAuth:false,
  login:()=>{

  },
  logout:()=>{

  }
})
const AuthContextProvider=props=>{
  useEffect(()=>{
  const token= localStorage.getItem("token");
    console.log(token)

    if (token===null || token===""){
    setIsAuthenticated(false)
  }else {
    setIsAuthenticated(true)
  }
  },[])
  const [isAuthenticated,setIsAuthenticated]=useState(false)
  const loginHandler=(username,password)=>{
    console.log(username,password)
    setIsAuthenticated(true)
  }
  const logoutHandler=(username,password)=>{
    localStorage.clear();
    setIsAuthenticated(false)
  }
  return <AuthContext.Provider value={{login:loginHandler,isAuth: isAuthenticated,logout:logoutHandler}}>
    {props.children}
  </AuthContext.Provider>
}
export default AuthContextProvider;