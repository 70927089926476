import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Page from '../../../components/Page';
import {Avatar, Box, Button, Checkbox, Container, FormHelperText, Link, Typography} from '@material-ui/core';
import Style from './/style.module.css'
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from '../../../axios-agent';
import LinearProgress from '@material-ui/core/LinearProgress';
import UploadCause from '../../../components/uploadCause';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  center:{
    display:"flex",
  justifyContent: "center",

},
}));
const useStylesButton =  makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const CauseNew=()=>{
  const btnClasses = useStylesButton();
  const classes = useStyles();
  const [isSubmitting,setIsSubmitting]=useState(false)
  const [message,setMessage]=useState('')

  const [organization_name,setOrganizationName]=useState("")
  const [logoUrl,setLogoUrl]=useState("");
  const [heroImage,setHeroImage]=useState("");
  const [urlSource,setUrlSource]=useState("")
  const [description,setDescription]=useState("");
  const [causeType,setCauseType]=useState(1);
  const [ein,setEIN]=useState("")

  function valueChangeHandler(event) {
    setErrorMessage("")

    setOrganizationName(event.target.value)
  }
  function urlSourceChangeHandler(event){
    setErrorMessage("")

    setUrlSource(event.target.value)
  }


  function descriptionChangeHandler(event) {
    setErrorMessage("")

    setMessage("")
    setDescription(event.target.value)
  }

  function keyChangeHandler(event) {
    setMessage("")
    setErrorMessage("")
    setEIN(event.target.value)
  }

  function typeChangeHandler(event) {
    setMessage("")
    setErrorMessage("")
    setCauseType(event.target.value)
  }

  function logoUrlHandler(event) {
    setMessage("")
    setErrorMessage("")
    setLogoUrl(event.target.value)

  }
  function heroImageHandler(event) {
    setMessage("")

    setErrorMessage("")
    setHeroImage(event.target.value)
  }

  const [errorMessage,setErrorMessage]=useState("");

  function createCauseHandler() {
    setErrorMessage("")

    setMessage("")
    if (organization_name==""){
      setErrorMessage("Value Could not be empty.")
      return
    }
    if ((ein=="") ||ein.includes(" ")){
      setErrorMessage("Key Could not be empty, or have space.")
      return
    }
    setIsSubmitting(true)
    const cause={
      organization_name:organization_name,
      ein:ein,
      logo_url:logoUrl,
      description:description,
      url:urlSource
    }
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    axios.post('/cause',
      cause).then(response=>{
        setMessage(          <Button
            variant="outlined"
            style={{borderColor:"blue"}}
            className={btnClasses.button}
            size="small"
            onClick={()=>{showCauseDetail(ein)}}
        >Nonprofit created, you can edit it here.</Button>)
      setIsSubmitting(false)
      console.log(response)
    }).catch(response=>{

      setIsSubmitting(false)
      setErrorMessage("Could not create Cause")
      console.log(response)
    })

  }

  const uploadHandler=(data)=>{
    console.log(data);
    setLogoUrl(data)
  };
  const navigate = useNavigate();
  function showCauseDetail(id){
    navigate('/app/cause/'+id)

  }
  return (
    <Page
      className={Style.center}
      title="Create Cause"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={Style.center}>

                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new cause
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Users could choose this cause.
                  </Typography>
                </Box>
          <Box
              component="img"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                height: 40,
                width: 40,
              }}
              alt={description}
              src={logoUrl}
          />
                <TextField
                  fullWidth
                  label="Organization Name"
                  margin="normal"
                  name="organization_name"
                  onChange={valueChangeHandler}
                  value={organization_name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Description"
                  margin="normal"
                  name="description"
                  multiline
                  rows={2}
                  onChange={descriptionChangeHandler}
                  value={description}
                  variant="outlined"
                />

                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    value={ein}
                    onChange={keyChangeHandler}
                    label="EIN"

                  />
                  {/*<Select*/}
                  {/*  label="Type"*/}
                  {/*  value={causeType}*/}
                  {/*  style={{marginLeft:"5px"}}*/}
                  {/*  onChange={typeChangeHandler}*/}
                  {/*>*/}
                  {/*  <MenuItem value={1}>General</MenuItem>*/}
                  {/*  <MenuItem value={2}>Backfill</MenuItem>*/}
                  {/*  <MenuItem value={3}>Special</MenuItem>*/}
                  {/*</Select>*/}
                </form>

                <TextField
                  fullWidth
                  label="Url source"
                  margin="normal"
                  name="َheroImage"
                  onChange={urlSourceChangeHandler}
                  value={urlSource}
                  variant="outlined"
                />
                <UploadCause  causeKey={ein} uploadHandler={uploadHandler}></UploadCause>

                <FormHelperText hidden={ein!=""}>
                  For uploading cause logo, You should type EIN first, Now it's disabled.
                </FormHelperText>

                <FormHelperText error>
                  {errorMessage}
                </FormHelperText>
          <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
               <b> {message}</b>
          </FormHelperText>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={createCauseHandler}
                  >
                    Create Cause
                  </Button>
                </Box>
          {isSubmitting? <LinearProgress />:null}
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  You want see cause list?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/app/cause"
                    variant="h6"
                  >
                  Cause List
                  </Link>
                </Typography>


        </Container>
      </Box>
    </Page>
  );
}
export default CauseNew;