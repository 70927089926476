import React, {useState,useEffect} from "react";
import {Alert, Button, Form, Spinner} from 'react-bootstrap';
import {Dropdown} from "semantic-ui-react";
import Classes from '../dropdown/style.module.css'
import Cookies from "universal-cookie";
function CauseDropdown(props) {
  const [causeSelected,setCause] = useState("Giving Forward")
  const [causeKey,setCauseKey] = useState('gf')
  const [logoUrl,setLogoUrl]=useState('https://u.givingforward.org/logo/gf.svg')
  const [loading,setLoading] =useState(true)
  const [causeList,SetCauseList] = useState([])
  const [Content,SetContent]=useState("")
  const getCookie=(key)=>{
    const cookies = new Cookies();
    let value = cookies.get(key);
    if (value==="" || value===undefined){
      value=localStorage.getItem(key);
    }
    return value;
  }
  useEffect(()=>{
    var causeTemp="gf";
    console.log(props.defaultCause);
    let defaultCause =props.defaultCause;
    if (defaultCause){
      causeTemp=defaultCause;
    }else {
      causeTemp="gf"
    }
    setCauseKey(causeTemp)

  },[props.defaultCause])
  useEffect(()=>{
    SetCauseList(props.causeList)
  },[props.causeList])

  useEffect(()=>{
    props.causeList.map(tempCause => {
      if (tempCause.key.includes(causeKey)) {
        console.log("tempCause.value: =",tempCause.value)
        setLogoUrl(`https://u.givingforward.org/logo/${tempCause.key}.svg`)
        setCause(tempCause.value)
      }
    });
  },[causeKey,props.causeList,causeList,loading])



  useEffect(()=>{
    causeList.map(tempCause => {
      if (tempCause.value.includes(causeSelected)) {

        setCauseKey(tempCause.key)
        console.log(causeKey)
        setLogoUrl(`https://u.givingforward.org/logo/${tempCause.key}.svg`)
        props.causeChanged(tempCause.key)

      }
    });
  },[causeSelected])
  useEffect(()=>{
    console.log(props.causeList.length)
    if (props.causeList!==0){
      setLoading(false)
      console.log("Loading end")
    }
  })

  const changeCauseHandler=(event)=>{
    //const changedCause=removeCharAt(event.target.textContent)
    setCause(event.target.textContent)
  }

  useEffect(()=>{
    let CurrentCause="";
    console.log(props.defaultCause)
    props.causeList.map(tempCause => {
      if (tempCause.key.includes(props.defaultCause)) {
         setCause(tempCause.value);
      }
    });
  },[causeList.length])
  useEffect(()=>{

    if (!loading) {
      SetContent(<div >
        Current cause is: {causeSelected}
        <br>
        </br>
        {causeSelected && <Dropdown
          onChange={changeCauseHandler.bind(this)}
          placeholder="You search for more cause here..."
          fluid
          search
          selection
          options={causeList}
        >
        </Dropdown>}
      </div>)
    }
  },[loading,props.causeList,causeList,causeKey,causeSelected,props.defaultCause])
  return(
    <div>
      {Content}
    </div>


  );

}
export default CauseDropdown;