import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.care2win.com/v1/'

});

export default instance;
// baseURL: 'http://127.0.0.1/v1/'
// baseURL: 'http://127.0.0.1:8080/v1/'
// baseURL: 'https://api.care2win.com/v1'