import React, { useEffect, useState } from 'react';
import { useNavigate, useParams  } from 'react-router-dom';
import axios from '../../../axios-agent';
import Card from '@material-ui/core/Card';
import moment from 'moment';

import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Label } from 'semantic-ui-react';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import ProfileDetails from '../../User/Detail/Detail';
import Profile from '../../User/Detail/Profile';
import Container from '@material-ui/core/Container';
import Page from '../../../components/Page';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));
function Account(props) {
  const classes = useStyles();
  const navigate =useNavigate()
  const [user,setUser]=useState()
  const [content,setContent]=useState(<div><LinearProgress />
    <LinearProgress color="secondary" /></div>)
  useEffect(()=>{
    let hash=localStorage.getItem('userid')
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
    axios.get('/user/'+hash).then(response => {
      setUser(response.data)


    }).catch(response=>{

    })

  },[])
  useEffect(()=>{
    if (user!==undefined){
      setContent(    <Page
        className={classes.root}
        title="User Detail"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <Profile name={user.first_name+" "+user.last_name} role={user.role}
                       email={user.email} />
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <ProfileDetails first_name={user.first_name} role={user.role}
                              last_name={user.last_name}
                              cmpp_registered={user.cmpp_registered}
                              id={localStorage.getItem('userid')}

              />
            </Grid>
          </Grid>
        </Container>
      </Page>)
    }
    console.log(user)
  },[user])
  return(
    <div>
      {content}
    </div>

  )
}
export default Account;