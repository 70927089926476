import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../axios-agent';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles, FormHelperText
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import crypto from "crypto-js";

import { AuthContext } from '../../context/auth-context';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [message,setMessage]=useState("")
  const [email,setEmail]=useState("");
  const [isSubmitting,setIsSubmitting]=useState(false)
  const [ErrorsMessage,setErrorsMessage]=useState("");
  const [login,setLogin]=useState(false);

  const [password,setPassword]=useState("");
  const emailChangeHandler=(event)=>{
    setErrorsMessage("")

    setEmail(event.target.value)
  }
  const passwordChangeHandler=(event)=>{
    setErrorsMessage("")

    setPassword(event.target.value)
  }
  const validateEmail=(email)=> {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  useEffect(()=>{
    if (login){
      authContext.login();
      navigate('/app/dashboard')
      //  navigate("/app/dashboard", { replace: true })
    }

  },[login])
  const loginHandler=()=>{
    setIsSubmitting(true)
    if (!validateEmail(email)){
      setErrorsMessage('Invalid email address.')
      setIsSubmitting(false)
      return
    }
    if(password==""){
      setErrorsMessage('Enter Password')
      setIsSubmitting(false)

      return
    }
    const user={
      email:email,
      password:password
    }
    axios.post('/login', user).then(response => {
      console.log(response)
      console.log( localStorage.setItem('firstname', response.data.firstname))
      setIsSubmitting(false)
      localStorage.setItem('firstname', response.data.firstname);
      const userid =crypto.MD5(response.data.email).toString()
      localStorage.setItem('userid', userid);
      localStorage.setItem('avatar',response.data.avatar)
      localStorage.setItem('role',response.data.role)
      localStorage.setItem('token', response.data.token);
      setLogin(true)
    }).catch(responseError=>{
        if( responseError.response ){
         // console.log(responseError.response.data); // => the response payload
          setIsSubmitting(false)
          setErrorsMessage(responseError.response.data.message)
        }else {
          setIsSubmitting(false)
          setErrorsMessage("You could not login")
        }
    })


  }
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">

              <div >
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                </Grid>

                <TextField
                  fullWidth
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onChange={emailChangeHandler}
                  type="email"
                  value={email}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  onChange={passwordChangeHandler}
                  type="password"
                  value={password}
                  variant="outlined"
                />
                <FormHelperText error>
                  <h3>   <b> {ErrorsMessage}</b></h3>
                </FormHelperText>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={loginHandler}
                  >
                    Sign in now
                  </Button>
                </Box>
                <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
                  <h3>   <b> {message}</b></h3>
                </FormHelperText>
                {isSubmitting? <LinearProgress />:null}

                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </div>

        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
