import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useNavigate} from "react-router-dom";
import crypto from "crypto-js";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import axios from "../../../axios-agent";
const useStylesButton =  makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const QrList=()=> {
    const btnClasses = useStylesButton();

    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();
    const [data,setData] =useState([]) ;
    const options = {
        rowCursorHand: true,
        selectableRowsHideCheckboxes:true,
    };
    function showDetail(index) {
        console.log(index)
       const hash=data[index][3]
        navigate('/app/qr/'+hash)

    }

    const columns = [
        {
            label: "Original URL",
            name: "Original URL",
            options: {
                filter: true,
            }
        },
        {
            name: "Converted URL",
            options: {
                filter: false,
            }
        },
        {
            name: "Visit Count",
            options: {
                filter: true,
            }
        },
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (

                        <Button
                            variant="outlined"
                            style={{borderColor:"blue"}}
                            size="small"
                            className={btnClasses.margin}
                            onClick={() => {
                                showDetail(dataIndex)
                            }}
                        >
                            EDIT
                        </Button>
                    );
                }
            }
        },
    ];
    useEffect(()=>{

        let tempData=[];
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
        axios.get('/qr/all').then(response => {
            setLoading(false)
            let tempUserList = response.data;
            if(tempUserList.length>0){
                tempUserList.map(qr=>{
                    const dataCell=Object.values(qr)

                 //   let  url= [dataCell[1], dataCell[0],, dataCell[2],dataCell[3]];
                    let  url= [];
                    console.log(dataCell)
                    if (dataCell[1]==""){
                        url.push("Reserved")
                    }else {
                        url.push(dataCell[1])
                    }
                    url.push(dataCell[0])

                    url.push(0)
                    url.push(dataCell[2])
                    url.push(dataCell[3])

                    tempData.push(url)
                });
                setData(tempData)
            }
            console.log(response.data);
        }).catch(error => {
            setLoading(false)
        })

    },[])

    return(
        <div style={{margin:"20px"}}>
            <MUIDataTable
                title={"QR List"}
                data={data}
                columns={columns}
                options={options}
            />
            {loading? <LinearProgress />:null}
        </div>
    )
}
export default QrList;